var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * @url /main
 * @CONST CONST.URL_MAIN_DASHBOARD
 * @menu e-Health Check
 * @mapping e-Health Checker 화면
 */
import React, { useState, useEffect } from "react";
import axios from "axios";
// recoil
import { useRecoilValue, useRecoilState } from "recoil";
import { authState, userInfoState, userInfoLoginState } from '../../recoil/userState';
// css
import "/static/css/style.css";
import "/static/css/content.css";
// utils
import clog from "../../utils/logUtils";
import * as HttpUtil from "../../utils/api/HttpUtil";
import * as CUTIL from "../../utils/commUtils";
import * as FILEUTIL from "../../utils/file/fileUtil";
// component
import Header from "../header/Header";
import Main from "../main/Main";
// ex-module
import JSZip from "jszip";
import { useTrans } from "../../utils/langs/useTrans";
function MainLayout() {
    const isAuth = useRecoilValue(authState);
    const [userInfo, setRecoilUserInfo] = useRecoilState(userInfoState);
    const [popListView, setPopListView] = useState(React.createElement(ListViewPopup, { popBody: null }));
    const [popListDetail, setPopListDetail] = useState(React.createElement(ListDetailPopup, { popBody: null }));
    const [popReason, setPopReason] = useState(React.createElement(ReasonPopup, { popBody: null }));
    useEffect(() => {
        setPopListView(popListView);
        setPopListDetail(popListDetail);
        setPopReason(popReason);
        //
    }, [popListView, popListDetail, popReason
    ]);
    function handlePopWin(popType, popObj) {
        if (popType === "pop-userinfo") {
            setPopListView(React.createElement(ListViewPopup, { popBody: popObj }));
        }
        else if (popType === "pop-list-view") {
            setPopListDetail(React.createElement(ListDetailPopup, { popBody: popObj }));
        }
        else if (popType === "pop-reason") {
            setPopReason(React.createElement(ReasonPopup, { popBody: popObj }));
        }
        function handleCurTabMenu(tab) {
            // 승인 관리
            setPopListView(React.createElement(ListViewPopup, { popBody: null }));
            setPopListDetail(React.createElement(ListDetailPopup, { popBody: null }));
            setPopReason(React.createElement(ReasonPopup, { popBody: null }));
            //
        }
        //
    }
    //
    const [itemDetail, setItemDetail] = useState({
        "ehcType": "BASIC",
        "ehcPos": "",
        "itemId": "",
        "itemName": "",
        "serialNo": "",
        "itemStatus": "",
        "itemStep": null,
        "responsible": null,
        "assessment": {
            "assessmentId": null,
            "totalComment": null,
            "reportId": null,
            "updatedTime": null,
            "isTempSave": null
        },
        "checkStep": { "checkStepId": null, "name": null }
    });
    const [reportInfo, setReportInfo] = useState(null);
    const [reportLoading, setReportLoading] = useState(false);
    function handleItemDetail(val) {
        setItemDetail(val);
    }
    function handleReportInfo(val) {
        clog("IN MAINLAYOUT : handleReportInfo : " + JSON.stringify(null));
        setReportInfo(val);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "wrap" },
            React.createElement(Header, null),
            React.createElement(Main, { setItemDetail: handleItemDetail, setReportInfo: handleReportInfo, reportLoading: reportLoading, setReportLoading: setReportLoading, setPopWin: handlePopWin })),
        React.createElement(PopupItemDetail, { itemDetail: itemDetail, setReportInfo: handleReportInfo, setReportLoading: setReportLoading }),
        React.createElement(PopupReportView, { itemDetail: itemDetail, reportInfo: reportInfo }),
        React.createElement(PopupMobileReportView, { itemDetail: itemDetail, reportInfo: reportInfo }),
        popListView,
        popListDetail,
        popReason));
}
export default MainLayout;
function PopupItemDetail(props) {
    //trans
    const t = useTrans();
    //recoil
    const userInfo = useRecoilValue(userInfoLoginState);
    //props
    const item = props.itemDetail;
    const setParentReportInfo = props.setReportInfo;
    const setParentReportLoading = props.setReportLoading;
    function onClickReportView(e, item) {
        setParentReportInfo(null);
        (() => __awaiter(this, void 0, void 0, function* () {
            setParentReportLoading(true);
            const resp = yield HttpUtil.API_reportView(item.serialNo);
            setParentReportInfo(resp);
            setParentReportLoading(false);
            CUTIL.jsopen_Popup(e);
        }))();
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "data-detail", className: "popup-layer js-layer layer-out hidden page-detail" },
            React.createElement("div", { className: "popup__head" },
                React.createElement("h1", null, t("LABEL.상세정보")),
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            React.createElement("div", { className: "popup__body" },
                React.createElement("ul", { className: "datadetail-info" },
                    React.createElement("li", null,
                        React.createElement("p", null, t("FIELD.시리얼번호")),
                        React.createElement("p", null, item.serialNo)),
                    React.createElement("li", null,
                        React.createElement("p", null, t("FIELD.담당자")),
                        React.createElement("p", null, item.responsible)),
                    React.createElement("li", null,
                        React.createElement("p", null, t("FIELD.위치")),
                        React.createElement("p", null, item.ehcPos)),
                    React.createElement("li", null,
                        React.createElement("p", null, t("FIELD.최근점검일")),
                        React.createElement("p", null, CUTIL.utc2time("YYYY-MM-DD", item.assessment.updatedTime)),
                        React.createElement("p", { className: "hide" }, "Step"),
                        React.createElement("ul", { className: (item.checkStep.name === "ADVANCED")
                                ? "icon-step all"
                                : (item.checkStep.name === "PREMIUM")
                                    ? "icon-step two"
                                    : (item.checkStep.name === "BASIC")
                                        ? "icon-step one"
                                        : "icon-step" },
                            React.createElement("li", null,
                                React.createElement("span", { className: "hide" }, "1\uB2E8\uACC4")),
                            React.createElement("li", null,
                                React.createElement("span", { className: "hide" }, "2\uB2E8\uACC4")),
                            React.createElement("li", null,
                                React.createElement("span", { className: "hide" }, "3\uB2E8\uACC4")))),
                    React.createElement("li", { className: "btnarea" },
                        React.createElement("div", null,
                            (item.assessment.reportId != null)
                                ? React.createElement("button", { type: "button", className: "btn btn-file", onClick: (e) => { HttpUtil.fileDownload_EhcReport(item.itemName, item.assessment.reportId, userInfo.loginInfo.token); } },
                                    React.createElement("span", { className: "hide" }, "\uD30C\uC77C\uB2E4\uC6B4\uB85C\uB4DC"))
                                : React.createElement("button", { type: "button", className: "btn btn-file", disabled: true },
                                    React.createElement("span", { className: "hide" }, "\uD30C\uC77C\uB2E4\uC6B4\uB85C\uB4DC")),
                            React.createElement("p", null, t("FIELD.Report"))),
                        React.createElement("div", null,
                            (item.assessment.totalComment == null)
                                ? React.createElement("button", { type: "button", className: "btn btn-memo", disabled: true },
                                    React.createElement("span", { className: "hide" }, "\uBA54\uBAA8"))
                                : React.createElement("button", { type: "button", className: "btn btn-memo" },
                                    React.createElement("span", { className: "hide" }, "\uBA54\uBAA8")),
                            React.createElement("p", null, t("FIELD.메모"))),
                        React.createElement("div", null,
                            (!item.serialNo)
                                ? React.createElement("button", { type: "button", className: "btn btn-file", disabled: true },
                                    React.createElement("span", { className: "hide" }, "\uC131\uC801\uC11C"))
                                : React.createElement("button", { type: "button", className: "btn btn-file js-open", "data-pop": "pop-downtest-mobile", onClick: (e) => onClickReportView(e, item) },
                                    React.createElement("span", { className: "hide" }, "\uD30C\uC77C\uB2E4\uC6B4\uB85C\uB4DC")),
                            React.createElement("p", null, t("FIELD.성적서")))))))));
}
function PopupReportView(props) {
    const item = props.itemDetail;
    const reportInfo = props.reportInfo;
    //const reportList = (reportInfo != null ) ? reportInfo.IF_RES_DATA : null;
    const [reportList, setReportList] = useState(null);
    const [checkAll, setCheckAll] = useState(false);
    const [checkReports, setCheckReports] = useState([]);
    useEffect(() => {
        //if ( reportInfo == null ) {
        //}
        setReportList((reportInfo != null) ? reportInfo.IF_RES_DATA : null);
        setCheckAll(false);
        //setCheckReports([]);
    }, [reportInfo]);
    function handleAllCheck(e) {
        var isChecked = e.target.checked;
        setCheckAll(isChecked);
        setReportList(reportList.map((report) => (Object.assign(Object.assign({}, report), { "checked": isChecked }))));
    }
    function handleCheckReports(e, check) {
        const isChecked = e.target.checked;
        const tmpReports = reportList.map((report) => (report.REPORT_NO === check.REPORT_NO) && Object.assign(Object.assign({}, report), { "checked": isChecked }));
        const tmpChecks = tmpReports.filter((report) => (report.checked));
        if (tmpChecks.length === tmpReports.length) {
            setCheckAll(true);
        }
        else {
            setCheckAll(false);
        }
        setReportList(tmpReports);
    }
    function pdfDownloadAll(e) {
        reportList.filter((report) => report.checked).map((report) => {
            clog("IN pdfDownloadAll : " + JSON.stringify(report));
        });
        let zips = new JSZip();
        let zipCnt = 0;
        const zipFileName = `${item.itemName}_검사성적서.zip`;
        reportList.filter((report) => report.checked).map((report) => {
            const sPos = (report.PDF_LINK.lastIndexOf('/') < 0) ? 0 : report.PDF_LINK.lastIndexOf('/');
            const ePos = (report.PDF_LINK.lastIndexOf('?') < 0) ? report.PDF_LINK.length : report.PDF_LINK.lastIndexOf('?');
            var fileName = report.PDF_LINK.substring(sPos + 1, ePos);
            axios({
                method: "GET",
                url: report.PDF_LINK,
                responseType: "blob",
            }).then((resp) => {
                zips.file(fileName, resp.data);
                zipCnt++;
                return zipCnt;
            }).then((zipCnt) => {
                if (zipCnt === reportList.length) {
                    //FILEUTILS.saveToFile_Chrome(fileName, response.data);
                    zips.generateAsync({ type: "blob" }).then(function (blob) {
                        FILEUTIL.saveToFile_Chrome(zipFileName, blob);
                    });
                }
            });
        });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-downtest", className: "popup-layer js-layer layer-out hidden w720" },
            React.createElement("div", { className: "popup__head" },
                React.createElement("h1", null, item.itemName),
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            React.createElement("div", { className: "popup__body" },
                React.createElement("div", { className: "tbl-list type5" },
                    React.createElement("table", { summary: "\uC120\uD0DD,\uD30C\uC77C \uBA85, \uC0DD\uC131\uC77C, \uCCA8\uBD80 \uD30C\uC77C \uD56D\uBAA9\uC73C\uB85C \uAD6C\uC131\uB41C \uC2DC\uD5D8\uC131\uC801\uC11C \uB2E4\uC6B4\uB85C\uB4DC \uBAA9\uB85D \uC785\uB2C8\uB2E4." },
                        React.createElement("caption", null, "\uC2DC\uD5D8\uC131\uC801\uC11C \uB2E4\uC6B4\uB85C\uB4DC \uBAA9\uB85D"),
                        React.createElement("colgroup", null,
                            React.createElement("col", { style: { "width": "20px" } }),
                            React.createElement("col", { style: { "width": "auto" } }),
                            React.createElement("col", { style: { "width": "17%" } }),
                            React.createElement("col", { style: { "width": "15%" } })),
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", { scope: "col", className: "w20" },
                                    React.createElement("input", { type: "checkbox", value: "", id: "t_all", onChange: (e) => handleAllCheck(e), checked: checkAll, disabled: (reportList) ? false : true }),
                                    React.createElement("label", { htmlFor: "t_all" },
                                        React.createElement("span", { className: "hide" }, "\uC120\uD0DD"))),
                                React.createElement("th", { scope: "col" }, "\uD30C\uC77C \uBA85"),
                                React.createElement("th", { scope: "col" }, "\uC0DD\uC131\uC77C"),
                                React.createElement("th", { scope: "col", className: "txt-center" }, "\uCCA8\uBD80 \uD30C\uC77C"))),
                        React.createElement("tbody", null, (reportList == null)
                            ? React.createElement("tr", { key: "report_null" },
                                React.createElement("td", { className: "d-sm-none w20" },
                                    React.createElement("p", { className: "icon-no ml-m10" },
                                        React.createElement("span", { className: "hide" }, "\uD30C\uC77C\uC5C6\uC74C"))),
                                React.createElement("td", { className: "d-sm-none" }, "\uB4F1\uB85D\uB41C \uC131\uC801\uC11C\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4."),
                                React.createElement("td", { className: "d-sm-none" }, "-"),
                                React.createElement("td", { className: "btn__tdarea" },
                                    React.createElement("p", { className: "icon-no gray" },
                                        React.createElement("span", { className: "hide" }, "\uD30C\uC77C\uC5C6\uC74C"))))
                            : (reportList) && reportList.map((report, idx) => (React.createElement("tr", { key: "report_" + idx },
                                React.createElement("td", { scope: "col", className: "w20" },
                                    React.createElement("input", { type: "checkbox", id: `chk_${idx.toString()}`, 
                                        //onChange={(e) => handleCheckReports(e, { ...report, "itemReportId": `${item.itemId}_${report.REPORT_NO}` })}
                                        onChange: (e) => handleCheckReports(e, report), checked: (report.hasOwnProperty("checked")) ? report.checked : false }),
                                    React.createElement("label", { htmlFor: `chk_${idx.toString()}` },
                                        React.createElement("span", { className: "hide" }, "\uC120\uD0DD"))),
                                React.createElement("td", null,
                                    React.createElement("p", { className: "ellipsis" }, report.TYPE)),
                                React.createElement("td", null, report.CREATION_DATE.substring(0, 4) + "-" + report.CREATION_DATE.substring(4, 6) + "-" + report.CREATION_DATE.substring(6, 8)),
                                React.createElement("td", null, (report.PDF_LINK == null)
                                    ? React.createElement("button", { type: "button", className: "btn btn-file", disabled: true },
                                        React.createElement("span", { className: "hide" }, "\uCCA8\uBD80 \uD30C\uC77C"))
                                    : React.createElement("a", { href: report.PDF_LINK, target: "_blank", rel: 'noreferrer' },
                                        React.createElement("button", { type: "button", className: "btn btn-file" },
                                            React.createElement("span", { className: "hide" }, "\uCCA8\uBD80 \uD30C\uC77C"))))))))))),
            (reportList == null)
                ? React.createElement("div", { className: "popup__footer brd-0 mb-10" },
                    React.createElement("button", { type: "button", className: "bg-gray js-close" },
                        React.createElement("span", null, "Cancel")),
                    React.createElement("button", { type: "button", className: "bg-gray" },
                        React.createElement("span", null, "Download")))
                : React.createElement("div", { className: "popup__footer brd-0 mb-10" },
                    React.createElement("button", { type: "button", className: "bg-gray js-close" },
                        React.createElement("span", null, "Cancel")),
                    (reportList.filter((report) => report.checked).length <= 0)
                        ? React.createElement("button", { type: "button", className: "bg-gray" },
                            React.createElement("span", null, "Download"))
                        : React.createElement("button", { type: "button", className: "js-close", onClick: (e) => pdfDownloadAll(e) },
                            React.createElement("span", null, "Download"))))));
}
function PopupMobileReportView(props) {
    const item = props.itemDetail;
    const reportInfo = props.reportInfo;
    //const reportList = (reportInfo != null ) ? reportInfo.IF_RES_DATA : null;
    const [reportList, setReportList] = useState(null);
    const [checkAll, setCheckAll] = useState(false);
    const [checkReports, setCheckReports] = useState([]);
    useEffect(() => {
        //if ( reportInfo == null ) {
        //}
        setReportList((reportInfo != null) ? reportInfo.IF_RES_DATA : null);
        setCheckAll(false);
        //setCheckReports([]);
    }, [reportInfo]);
    function handleAllCheck(e) {
        var isChecked = e.target.checked;
        setCheckAll(isChecked);
        setReportList(reportList.map((report) => (Object.assign(Object.assign({}, report), { "checked": isChecked }))));
    }
    function handleCheckReports(e, check) {
        const isChecked = e.target.checked;
        const tmpReports = reportList.map((report) => (report.REPORT_NO === check.REPORT_NO) && Object.assign(Object.assign({}, report), { "checked": isChecked }));
        const tmpChecks = tmpReports.filter((report) => (report.checked));
        if (tmpChecks.length === tmpReports.length) {
            setCheckAll(true);
        }
        else {
            setCheckAll(false);
        }
        setReportList(tmpReports);
    }
    function pdfDownloadAll(e) {
        reportList.filter((report) => report.checked).map((report) => {
            clog("IN pdfDownloadAll : " + JSON.stringify(report));
        });
        let zips = new JSZip();
        let zipCnt = 0;
        const zipFileName = `${item.itemName}_검사성적서.zip`;
        reportList.filter((report) => report.checked).map((report) => {
            const sPos = (report.PDF_LINK.lastIndexOf('/') < 0) ? 0 : report.PDF_LINK.lastIndexOf('/');
            const ePos = (report.PDF_LINK.lastIndexOf('?') < 0) ? report.PDF_LINK.length : report.PDF_LINK.lastIndexOf('?');
            var fileName = report.PDF_LINK.substring(sPos + 1, ePos);
            axios({
                method: "GET",
                url: report.PDF_LINK,
                responseType: "blob",
            }).then((resp) => {
                zips.file(fileName, resp.data);
                zipCnt++;
                return zipCnt;
            }).then((zipCnt) => {
                if (zipCnt === reportList.length) {
                    //FILEUTILS.saveToFile_Chrome(fileName, response.data);
                    zips.generateAsync({ type: "blob" }).then(function (blob) {
                        FILEUTIL.saveToFile_Chrome(zipFileName, blob);
                    });
                }
            });
        });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-downtest-mobile", className: "popup-layer js-layer hidden page-detail" },
            React.createElement("div", { className: "popup__head" },
                React.createElement("h1", null, item.itemName),
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            React.createElement("div", { className: "popup__body" },
                React.createElement("div", { className: "tbl-list type5" },
                    React.createElement("table", { summary: "\uC120\uD0DD,\uD30C\uC77C \uBA85, \uC0DD\uC131\uC77C, \uCCA8\uBD80 \uD30C\uC77C \uD56D\uBAA9\uC73C\uB85C \uAD6C\uC131\uB41C \uC2DC\uD5D8\uC131\uC801\uC11C \uB2E4\uC6B4\uB85C\uB4DC \uBAA9\uB85D \uC785\uB2C8\uB2E4." },
                        React.createElement("caption", null, "\uC2DC\uD5D8\uC131\uC801\uC11C \uB2E4\uC6B4\uB85C\uB4DC \uBAA9\uB85D"),
                        React.createElement("colgroup", null,
                            React.createElement("col", { className: "width: 80%" }),
                            React.createElement("col", { className: "width: 20%" })),
                        React.createElement("tbody", null, (reportList == null)
                            ? React.createElement("tr", { key: "report_null" },
                                React.createElement("td", null,
                                    React.createElement("span", null, "\uB4F1\uB85D\uB41C \uC131\uC801\uC11C\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4."),
                                    React.createElement("span", null, "-")),
                                React.createElement("td", { className: "btn__tdarea" },
                                    React.createElement("button", { type: "button", className: "btn btn-file", disabled: true },
                                        React.createElement("span", { className: "hide" }, "\uCCA8\uBD80 \uD30C\uC77C"))))
                            : (reportList) && reportList.map((report, idx) => (React.createElement("tr", { key: "report_" + idx },
                                React.createElement("td", null,
                                    React.createElement("span", null, report.TYPE),
                                    React.createElement("span", null, report.CREATION_DATE.substring(0, 4) + "-" + report.CREATION_DATE.substring(4, 6) + "-" + report.CREATION_DATE.substring(6, 8))),
                                React.createElement("td", { className: "btn__tdarea" }, (report.PDF_LINK == null)
                                    ? React.createElement("button", { type: "button", className: "btn btn-file", disabled: true },
                                        React.createElement("span", { className: "hide" }, "\uCCA8\uBD80 \uD30C\uC77C"))
                                    : React.createElement("a", { href: report.PDF_LINK, target: "_blank", rel: 'noreferrer' },
                                        React.createElement("button", { type: "button", className: "btn btn-file" },
                                            React.createElement("span", { className: "hide" }, "\uCCA8\uBD80 \uD30C\uC77C"))))))))))))));
}
///
// 상세 팝업
function ListViewPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-userinfo", className: "popup-layer js-layer layer-out hidden page-report page-detail page-workplace page-info w720" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// 모바일 상세 팝업
function ListDetailPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-list-view", className: "popup-layer js-layer layer-out hidden page-report page-detail page-workplace page-info page-list-view" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
function ReasonPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-reason", className: "popup-layer popup-basic hidden page-detail w400" },
            React.createElement("div", { className: "page-detail" },
                React.createElement("div", { className: "popup__head" },
                    (htmlHeader) && htmlHeader,
                    React.createElement("button", { className: "btn btn-close js-close" },
                        React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
                (CUTIL.isnull(popBody))
                    ? React.createElement(React.Fragment, null)
                    : popBody))));
}
