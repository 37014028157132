import { atom, selector } from "recoil";
import clog from "../utils/logUtils";
import * as CUTIL from "../utils/commUtils";
export const nextItemState = atom({
    key: 'nextItemState',
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        spgTree: { company: { companyId: "", companyName: "" },
            zone: { zoneId: "", zoneName: "" },
            subZone: { subZoneId: "", subZoneName: "" },
            room: { roomId: "", roomName: "" },
            spg: { spgId: -1, spgName: "" }
        },
        ehcType: "",
        itemId: "",
        itemName: "",
        serialNo: "",
        itemStatus: "",
        itemStep: "",
        responsible: "",
        assessment: {
            preAssessmentId: null,
            assessmentId: null,
            totalComment: null,
            reportId: null,
            updatedTime: null,
            isTempSave: null
        }
    }
});
export const beforeItemState = atom({
    key: 'beforeItemState',
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        spgTree: { company: { companyId: "", companyName: "" },
            zone: { zoneId: "", zoneName: "" },
            subZone: { subZoneId: "", subZoneName: "" },
            room: { roomId: "", roomName: "" },
            spg: { spgId: -1, spgName: "" }
        },
        ehcType: "",
        itemId: "",
        itemName: "",
        serialNo: "",
        itemStatus: "",
        itemStep: "",
        responsible: "",
        assessment: {
            preAssessmentId: null,
            assessmentId: null,
            totalComment: null,
            reportId: null,
            updatedTime: null,
            isTempSave: null
        }
    }
});
export const itemState = atom({
    key: 'itemState',
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        spgTree: { company: { companyId: "", companyName: "" },
            zone: { zoneId: "", zoneName: "" },
            subZone: { subZoneId: "", subZoneName: "" },
            room: { roomId: "", roomName: "" },
            spg: { spgId: -1, spgName: "" }
        },
        ehcType: "",
        itemId: "",
        itemName: "",
        serialNo: "",
        itemStatus: "",
        itemStep: "",
        responsible: "",
        assessment: {
            preAssessmentId: null,
            assessmentId: null,
            totalComment: null,
            reportId: null,
            updatedTime: null,
            isTempSave: null
        }
    }
});
export const itemSelectState = selector({
    key: 'itemSelectState',
    // key의 값은 항상 고유값이어야 합니다.
    get: ({ get }) => {
        const itemInfo = get(itemState); // 현재 아이템 체크 여부
        return ((itemInfo.itemId.length > 0) ? true : false);
    },
});
export const assessmentListState = atom({
    key: 'assessmentListState',
    // key의 값은 항상 고유값이어야 합니다.
    default: [{
            checkItemId: -1,
            checkItemName: "",
            abbreviation: "",
            description: "",
            weight: -1,
            rangeMin: -1,
            rangeMax: -1,
            rangeInterval: -1,
            versionNo: "",
            spgDto: { spgId: -1, name: "" },
            checkStepDto: { checkStepId: -1, name: "" },
            checkGroupDto: { groupId: -1, groupName: "" },
            checkItemRefDtoList: [{ score: -1, reference: "" }],
        }]
});
export const curCheckValueDto = atom({
    key: "curCheckValueDto",
    default: {
        assessmentId: -1,
        checkItemId: -1,
        checkItemName: "",
        isChecked: false,
        value: "",
        valueType: "",
        comment: "",
        versionNo: ""
    }
});
export const tempCheckValue = atom({
    key: "tempCheckValue",
    default: {
        item: {
            spgTree: { company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "" },
                subZone: { subZoneId: "", subZoneName: "" },
                room: { roomId: "", roomName: "" },
                spg: { spgId: -1, spgName: "" }
            },
            ehcType: "",
            itemId: "",
            itemName: "",
            serialNo: "",
            itemStatus: "",
            itemStep: "",
            responsible: "",
            assessment: {
                preAssessmentId: null,
                assessmentId: null,
                totalComment: null,
                reportId: null,
                updatedTime: null,
                isTempSave: null
            }
        },
        checkVal: {
            assessmentId: -1,
            checkItemId: -1,
            checkItemName: "",
            isChecked: false,
            value: "",
            valueType: "",
            comment: "",
            versionNo: ""
        },
        stepDone: false,
    }
});
export const curSpgTreeState = atom({
    key: "curSpgTreeState",
    default: {
        company: { companyId: "", companyName: "" },
        zone: { zoneId: "", zoneName: "" },
        subZone: { subZoneId: "", subZoneName: "" },
        room: { roomId: "", roomName: "" },
        spg: { spgId: -1, spgName: "" }
    },
});
export const nextSpgTreeState = atom({
    key: "nextSpgTreeState",
    default: {
        company: { companyId: "", companyName: "" },
        zone: { zoneId: "", zoneName: "" },
        subZone: { subZoneId: "", subZoneName: "" },
        room: { roomId: "", roomName: "" },
        spg: { spgId: -1, spgName: "" }
    },
});
export const beforeSpgTreeState = atom({
    key: "beforeSpgTreeState",
    default: {
        company: { companyId: "", companyName: "" },
        zone: { zoneId: "", zoneName: "" },
        subZone: { subZoneId: "", subZoneName: "" },
        room: { roomId: "", roomName: "" },
        spg: { spgId: -1, spgName: "" }
    },
});
export const curEhcTypeState = atom({
    key: 'curEhcTypeState',
    default: "BASIC",
});
export const beforeEhcTypeState = atom({
    key: 'beforeEhcTypeState',
    default: "",
});
export const nextEhcTypeState = atom({
    key: 'nextEhcTypeState',
    default: "",
});
export const getTempSave = selector({
    key: 'getTempSave',
    // key의 값은 항상 고유값이어야 합니다.
    get: ({ get }) => {
        //
        const nextSpgTree = get(nextSpgTreeState);
        const nextEhcType = get(nextEhcTypeState); // 
        const nextItem = get(nextItemState); //
        const cval = get(tempCheckValue);
        const checkValue = (cval.checkVal.value.length > 0) ? parseInt(cval.checkVal.value) : -1;
        clog("IN RECOIL : getTempSave : " + checkValue + " : " + cval.checkVal.value);
        //clog("IN RECOIL : getTempSave : " + nextEhcType + " : " + JSON.stringify(nextItem));
        let ret = false;
        if (checkValue > -1) { // 임시저장 정보가 있으면
            if (nextItem.itemId.length > 0) { // 클릭된 아이템이 있으면
                if (nextItem.itemId != cval.item.itemId) { // 클릭된 아이템과 현재 선택된(진행중인) 아이템과 틀리면
                    clog("IN RECOIL : setTempSave true 1 : ");
                    ret = true;
                }
            }
            if (!CUTIL.isnull(nextEhcType)) { // 클릭된 EHCTYPE(STAUS) 있으면
                if (nextEhcType != cval.item.ehcType) { // 클릭된 EHCTYPE과 현재 선택된(진행중인) 아이템의 EHCTYPE와 틀리면
                    clog("IN RECOIL : setTempSave true 2 : " + nextEhcType);
                    ret = true;
                }
            }
            //clog("IN RECOIL : getTempSave : " + JSON.stringify(nextSpgTree) + " : " + JSON.stringify(cval.item.spgTree) + " : " + nextSpgTree.spg.spgId);      
            if (nextSpgTree.spg.spgId > -1) { // 클릭된 EHCTYPE(STAUS) 있으면
                if (nextSpgTree.company.companyId != cval.item.spgTree.company.companyId) { // 클릭된 spgTree과 현재 선택된(진행중인) 아이템의 spgTree과 틀리면
                    clog("IN RECOIL : setTempSave true 3-1 : ");
                    ret = true;
                }
                else if (nextSpgTree.zone.zoneId != cval.item.spgTree.zone.zoneId) {
                    clog("IN RECOIL : setTempSave true 3-2 : ");
                    ret = true;
                }
                else if (nextSpgTree.subZone.subZoneId != cval.item.spgTree.subZone.subZoneId) {
                    clog("IN RECOIL : setTempSave true 3-3 : ");
                    ret = true;
                }
                else if (nextSpgTree.room.roomId != cval.item.spgTree.room.roomId) {
                    clog("IN RECOIL : setTempSave true 3-4 : ");
                    ret = true;
                }
                else if (nextSpgTree.spg.spgId != cval.item.spgTree.spg.spgId) {
                    clog("IN RECOIL : setTempSave true 3-5 : ");
                    ret = true;
                }
            }
        }
        clog("IN RECOIL : getTempSave : RETURN : " + ret);
        return (ret);
    },
});
export const getTempSaveOld = selector({
    key: 'getTempSaveOld',
    // key의 값은 항상 고유값이어야 합니다.
    get: ({ get }) => {
        const nextItem = get(nextItemState);
        //
        const cval = get(tempCheckValue);
        //clog("IN RECOIL : NEXTITEM : " + JSON.stringify(nextItem));
        //clog("IN RECOIL : TEMPVAL : " + JSON.stringify(cval));
        const checkValue = (cval.checkVal.value.length > 0) ? parseInt(cval.checkVal.value) : -1;
        //clog("IN RECOIL : TEMPVAL : RETURN : " + ((nextItem.itemId != cval.item.itemId)&&(checkValue > -1)));
        return (((nextItem.itemId != cval.item.itemId) && (cval.checkVal.value.length > 0)) ? true : false);
        //return (((nextItem.itemId != cval.item.itemId)&&(checkValue > -1))?true:false);
    },
});
export const getStepDone = selector({
    key: 'getStepDone',
    // key의 값은 항상 고유값이어야 합니다.
    get: ({ get }) => {
        //
        const cval = get(tempCheckValue);
        return (cval.stepDone);
    },
});
export const doneAssessmentState = atom({
    key: 'doneAssessmentState',
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        assessmentId: -1,
        updatedTime: "",
        isTempSave: true,
        itemId: "",
        stepName: "",
        versionNo: "",
        checkValueDtoList: [{
                assessmentId: -1,
                checkItemId: -1,
                checkItemName: "",
                isChecked: false,
                value: "",
                valueType: "",
                comment: "",
                versionNo: ""
            }],
    }
});
export const getCheckValueDtoList = selector({
    key: 'getCheckValueDtoList',
    // key의 값은 항상 고유값이어야 합니다.
    get: ({ get }) => {
        const doneAssessmentInfo = get(doneAssessmentState);
        return (doneAssessmentInfo.checkValueDtoList);
    },
});
export const getCheckValueDto = selector({
    key: 'getCheckValueDto',
    // key의 값은 항상 고유값이어야 합니다.
    get: ({ get }) => {
        const doneAssessmentInfo = get(doneAssessmentState);
        const stepVal = get(checkStep);
        return ((stepVal < 0) ? doneAssessmentInfo.checkValueDtoList[0] : doneAssessmentInfo.checkValueDtoList[stepVal]);
    },
});
export const setCheckValueDtoList = selector({
    key: 'setCheckValueDtoList',
    // key의 값은 항상 고유값이어야 합니다.
    get: ({ get }) => { const doneAssessmentInfo = get(doneAssessmentState); },
    /*
      set:({set}, val) => {
        set(doneAssessmentState,
          val instanceof DefaultValue ? val : doneAssessmentState);
      },
    */
});
export const checkStep = atom({
    key: 'checkStep',
    // key의 값은 항상 고유값이어야 합니다.
    default: -1
});
export const getCurItemCheck = selector({
    key: 'getCurItemCheck',
    // key의 값은 항상 고유값이어야 합니다.
    get: ({ get }) => {
        const assessmentList = get(assessmentListState);
        const stepVal = get(checkStep);
        clog("IN RECOIL : GET STEP INFO : " + stepVal + " : LIST LEN : " + assessmentList.length);
        const isNull = (stepVal >= assessmentList.length) ? true : false;
        return ((stepVal < 0) ? assessmentList[0] : (isNull) ? null : assessmentList[stepVal]);
    },
});
export const getCheckDone = selector({
    key: 'getCheckDone',
    // key의 값은 항상 고유값이어야 합니다.
    get: ({ get }) => {
        const assessmentList = get(assessmentListState);
        const stepVal = get(checkStep);
        return ((assessmentList.length == stepVal) ? true : false);
    },
});
