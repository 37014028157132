/**
 * @url /admin , /adminitem
 * @CONST CONST.URL_ADMIN , CONST.URL_ADMIN_ITEM
 * @mapping 운영관리 > 사업장 전기실 관리 화면 , 기기등록 관리 화면
 */
import React, { useState, useEffect } from "react";
// recoil
import { useRecoilState } from "recoil";
import { urlState, menuInfoState, menuListState } from '../../recoil/menuState';
//css
import "/static/css/swiper.css";
import * as CONST from "../../utils/Const";
import * as CUTIL from "../../utils/commUtils";
//component
import Header from "../header/Header";
import AdminMain from "../admin/AdminMain";
import EhpImageCrop from "../common/imagecrop/EhpImageCrop";
import EhpPostCode from "../common/postcode/EhpPostCode";
function AdminLayout(props) {
    //recoil
    const [urlInfo, setRecoilUrlInfo] = useRecoilState(urlState);
    const [menuInfo, setRecoilMenuInfo] = useRecoilState(menuInfoState);
    const [menuRecoilList, setRecoilMenuList] = useRecoilState(menuListState);
    const tabId = props.hasOwnProperty("tabId") ? props.tabId : 0;
    //tab init
    const tabMenuList1 = [
        { tabId: 0, tabName: "사업장 전기실 관리", tabUrl: CONST.URL_ADMIN, tabType: "TAB_ZONE" },
        { tabId: 1, tabName: "기기 등록 관리", tabUrl: CONST.URL_ADMIN_ITEM, tabType: "TAB_DEVICE" },
    ];
    const [tabMenuList, setTabMenuList] = useState(null);
    const [curTabMenu, setCurTabMenu] = useState(null);
    // 특정 화면에서 url로 menu 정보 추출하기 위함
    useEffect(() => {
        let tmenuList = menuRecoilList;
        if (tmenuList.length <= 0) {
            //tmenuList = JSON.parse(sessionStorage.getItem(CONST.STR_EHP_MENULIST)); 
        }
        //clog("IN LAYOUT : MANAGEMENT : TMENU LIST : " + JSON.stringify(tmenuList));
        let curMenu = null;
        tmenuList.filter((smenu) => smenu.url === urlInfo).map((smenu, idx) => {
            if (idx === 0)
                curMenu = smenu;
        });
        //clog("IN LAYOUT : MANAGEMENT : CUR MENU : " + JSON.stringify(curMenu));
        const tempTab = [];
        tmenuList.filter((smenu) => (curMenu) && (smenu.parentCode === curMenu.parentCode)).map((smenu, idx) => {
            /*
            if (idx===tabId) {
              setCurTabMenu({
                "tabId":idx,
                "tabName":smenu.menuName,
                "tabUrl":smenu.url,
                "tabType":(smenu.url===CONST.URL_ADMIN)?"TAB_ZONE":(smenu.url===CONST.URL_ADMIN_ITEM)?"TAB_DEVICE":""
              });
            }*/
            tempTab.push({
                "tabId": idx,
                "tabName": smenu.menuName,
                "tabUrl": smenu.url,
                "tabType": (smenu.url === CONST.URL_ADMIN) ? "TAB_ZONE" : (smenu.url === CONST.URL_ADMIN_ITEM) ? "TAB_DEVICE" : ""
            });
        });
        setTabMenuList(tempTab);
        //clog("IN LAYOUT : MANAGEMENT : TMENU LIST : " + JSON.stringify(tempTab));
        setCurTabMenu(tempTab[tabId]);
    }, [urlInfo, tabId, menuRecoilList]);
    //pop-up
    const [popAreaRight, setPopAreaRight] = useState(React.createElement(AreaRightPopup, { popBody: null }));
    const [popAreaRightPageInfo, setPopAreaRightPageInfo] = useState(React.createElement(AreaRightPageInfoPopup, { popBody: null }));
    const [popList, setPopList] = useState(React.createElement(ListPopup, { popBody: null }));
    const [popListView, setPopListView] = useState(React.createElement(ListViewPopup, { popBody: null }));
    const [popDeviceAdd, setPopDeviceAdd] = useState(React.createElement(DeviceAddPopup, { popBody: null }));
    const [popDeviceAllAdd, setPopDeviceAllAdd] = useState(React.createElement(DeviceAllAddPopup, { popBody: null }));
    const [popFinishOk, setPopFinishOk] = useState(React.createElement(FinishOkPopup, { popBody: null }));
    const [popFinishOkWeb, setPopFinishOkWeb] = useState(React.createElement(FinishOkWebPopup, { popBody: null }));
    // by hjo - 20220919 이미지 삭제 팝업 추가
    const [popDelete, setPopDelete] = useState(React.createElement(DeletePopup, { popBody: null }));
    const [popImageDel, setPopImageDel] = useState(React.createElement(ImageDeletePopup, { popBody: null }));
    // pop-workplace
    const [popWorkPlace, setPopWorkPlace] = useState(React.createElement(WorkPlacePopup, { popBody: null }));
    const [popReason, setPopReason] = useState(React.createElement(ReasonPopup, { popBody: null }));
    // search
    const [popSearch, setPopSearch] = useState(React.createElement(SearchPopup, { popBody: null }));
    // crop
    const [popImgCrop, setPopImgCrop] = useState(React.createElement(EhpImageCrop, { popBody: null }));
    const [popPostCode, setPopPostCode] = useState(React.createElement(EhpPostCode, { popBody: null }));
    //
    useEffect(() => {
        setPopAreaRight(popAreaRight);
        setPopAreaRightPageInfo(popAreaRightPageInfo);
        setPopList(popList);
        setPopDeviceAdd(popDeviceAdd);
        setPopDeviceAllAdd(popDeviceAllAdd);
        setPopFinishOk(popFinishOk);
        setPopFinishOkWeb(popFinishOkWeb);
        setPopWorkPlace(popWorkPlace);
        setPopReason(popReason);
        setPopSearch(popSearch);
        // crop components
        setPopImgCrop(popImgCrop);
        setPopPostCode(popPostCode);
        // by hjo - 20220919 이미지 삭제 팝업 추가
        setPopDelete(popDelete);
        setPopImageDel(popImageDel);
    }, [popAreaRight, popAreaRightPageInfo,
        popList, popDeviceAdd,
        popDeviceAllAdd, popFinishOk,
        popFinishOkWeb, popWorkPlace,
        popReason,
        popSearch, popImgCrop, popPostCode,
        // by hjo - 20220919 이미지 삭제 팝업 추가
        popDelete, popImageDel,
    ]);
    function handlePopWin(popType, popObj) {
        if (popType === "pop-area-right") {
            setPopAreaRight(React.createElement(AreaRightPopup, { popBody: popObj }));
        }
        else if (popType === "pop-area-right-page-info") {
            setPopAreaRightPageInfo(React.createElement(AreaRightPageInfoPopup, { popBody: popObj }));
        }
        else if (popType === "pop-list") {
            setPopList(React.createElement(ListPopup, { popBody: popObj }));
        }
        else if (popType === "pop-list-view") {
            setPopListView(React.createElement(ListViewPopup, { popBody: popObj }));
        }
        else if (popType === "pop-deviceadd") {
            setPopDeviceAdd(React.createElement(DeviceAddPopup, { popBody: popObj }));
        }
        else if (popType === "pop-devicealladd") {
            setPopDeviceAllAdd(React.createElement(DeviceAllAddPopup, { popBody: popObj }));
        }
        else if (popType === "pop-finish-ok") {
            setPopFinishOk(React.createElement(FinishOkPopup, { popBody: popObj }));
        }
        else if (popType === "pop-finish-ok-web") {
            setPopFinishOkWeb(React.createElement(FinishOkWebPopup, { popBody: popObj }));
        }
        else if (popType === "pop-workplace") {
            setPopWorkPlace(React.createElement(WorkPlacePopup, { popBody: popObj }));
        }
        else if (popType === "pop-reason") {
            setPopReason(React.createElement(ReasonPopup, { popBody: popObj }));
        }
        else if (popType === "pop-search-small") {
            setPopSearch(React.createElement(SearchPopup, { popBody: popObj }));
        }
        else if (popType === "pop-imgcrop") {
            setPopImgCrop(React.createElement(EhpImageCrop, { popBody: popObj }));
        }
        else if (popType === "pop-postcode") {
            setPopPostCode(React.createElement(EhpPostCode, { popBody: popObj }));
        }
        else if (popType === "pop-delete") { // by hjo -20220919 삭제 팝업 추가
            setPopDelete(React.createElement(DeletePopup, { popBody: popObj }));
        }
        else if (popType === "pop-imgdelete") { // by hjo -20220919 이미지 삭제 팝업 추가
            setPopImageDel(React.createElement(ImageDeletePopup, { popBody: popObj }));
        }
        else {
            setPopAreaRight(React.createElement(AreaRightPopup, { popBody: popObj }));
        }
    }
    function handleCurTabMenu(tab) {
        setPopAreaRight(React.createElement(AreaRightPopup, { popBody: null }));
        setPopAreaRightPageInfo(React.createElement(AreaRightPageInfoPopup, { popBody: null }));
        setPopList(React.createElement(ListPopup, { popBody: null }));
        setPopListView(React.createElement(ListViewPopup, { popBody: null }));
        setPopDeviceAdd(React.createElement(DeviceAddPopup, { popBody: null }));
        setPopDeviceAllAdd(React.createElement(DeviceAllAddPopup, { popBody: null }));
        setPopFinishOk(React.createElement(FinishOkPopup, { popBody: null }));
        setPopFinishOkWeb(React.createElement(FinishOkWebPopup, { popBody: null }));
        setPopWorkPlace(React.createElement(WorkPlacePopup, { popBody: null }));
        setPopReason(React.createElement(ReasonPopup, { popBody: null }));
        //
        setPopSearch(React.createElement(SearchPopup, { popBody: null }));
        //crop components
        setPopImgCrop(React.createElement(EhpImageCrop, { popBody: null }));
        setPopPostCode(React.createElement(EhpPostCode, { popBody: null }));
        // by hjo - 20220919 이미지 삭제 팝업 추가
        setPopDelete(React.createElement(DeletePopup, { popBody: null }));
        setPopImageDel(React.createElement(ImageDeletePopup, { popBody: null }));
        //
        setCurTabMenu(tab);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "wrap" },
            React.createElement(Header, null),
            (menuRecoilList) && (curTabMenu) && React.createElement(AdminMain, { setPopWin: handlePopWin, tabMenuList: tabMenuList, curTabMenu: curTabMenu, setCurTabMenu: handleCurTabMenu })),
        popAreaRight,
        popAreaRightPageInfo,
        popList,
        popListView,
        popDeviceAdd,
        popDeviceAllAdd,
        popFinishOk,
        popFinishOkWeb,
        popWorkPlace,
        popReason,
        popSearch,
        popImgCrop,
        popPostCode,
        popDelete,
        popImageDel));
}
export default AdminLayout;
function SearchPopup(props) {
    const popBody = props.popBody;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-search-small", className: "popup-layer layer-out js-layer hidden page-detail page-report page-list-in" },
            React.createElement("div", { className: "popup__head" },
                React.createElement("h1", { className: "icon-search" }, "\uAC80\uC0C9"),
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (popBody === null)
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
function AreaRightPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-area-right", className: "popup-layer js-layer layer-out hidden page-detail page-workplace" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
function AreaRightPageInfoPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-area-right-page-info", className: "popup-layer js-layer layer-out hidden page-detail page-workplace page-info" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
function ListPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-list", className: "popup-layer js-layer layer-out hidden page-detail page-workplace page-info page-list" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                (htmlHeaderBtn) && htmlHeaderBtn),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
function ListViewPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-list-view", className: "popup-layer js-layer layer-out hidden page-report page-detail page-workplace page-info page-list-view" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
function DeviceAllAddPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-devicealladd", className: "popup-layer js-layer layer-out hidden page-report page-detail page-workplace page-info page-list-view" },
            React.createElement("div", { className: "popup__head pb-20" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
function DeviceAddPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-deviceadd", className: "popup-layer js-layer layer-out hidden page-report page-detail page-workplace page-info page-list-view" },
            React.createElement("div", { className: "popup__head pb-20" },
                (htmlHeader) && htmlHeader,
                (htmlHeaderBtn) && htmlHeaderBtn),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
function FinishOkPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-finish-ok", className: "popup-layer js-layer layer-out hidden page-detail page-workplace page-list-in" },
            React.createElement("div", { className: "popup__head pb-20" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
function FinishOkWebPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-finish-ok-web", className: "popup-layer js-layer layer-out layer-out hidden page-list-in h80vh" },
            React.createElement("div", { className: "popup__head pb-20" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
function WorkPlacePopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-workplace", className: "popup-layer js-layer layer-out hidden popup-basic popup-logout" },
            React.createElement("div", { className: "page-detail" },
                React.createElement("div", { className: "popup__head" },
                    (htmlHeader) && htmlHeader,
                    React.createElement("button", { className: "btn btn-close js-close" },
                        React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
                (CUTIL.isnull(popBody))
                    ? React.createElement(React.Fragment, null)
                    : popBody))));
}
function ReasonPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-reason", className: "popup-layer popup-basic hidden page-detail w400" },
            React.createElement("div", { className: "page-detail" },
                React.createElement("div", { className: "popup__head" },
                    (htmlHeader) && htmlHeader,
                    React.createElement("button", { className: "btn btn-close js-close" },
                        React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
                (CUTIL.isnull(popBody))
                    ? React.createElement(React.Fragment, null)
                    : popBody))));
}
// by hjo -20220919 이미지삭제 팝업 추가
function DeletePopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-delete", className: "popup-layer js-layer hidden layer-out popup-basic" },
            React.createElement("div", { className: "page-detail" },
                React.createElement("div", { className: "popup__head" },
                    (htmlHeader) && htmlHeader,
                    React.createElement("button", { className: "btn btn-close js-close" },
                        React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
                (CUTIL.isnull(popBody))
                    ? React.createElement(React.Fragment, null)
                    : popBody))));
}
function ImageDeletePopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-imgdelete", className: "popup-layer js-layer hidden layer-out popup-basic" },
            React.createElement("div", { className: "page-detail" },
                React.createElement("div", { className: "popup__head" },
                    (htmlHeader) && htmlHeader,
                    React.createElement("button", { className: "btn btn-close js-close" },
                        React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
                (CUTIL.isnull(popBody))
                    ? React.createElement(React.Fragment, null)
                    : popBody))));
}
