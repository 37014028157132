var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//utils
import * as CONST from "../../utils/Const";
import clog from "../../utils/logUtils";
import * as CUTIL from "../../utils/commUtils";
import * as HttpUtil from "../../utils/api/HttpUtil";
/**
 * @brief 사용자 상세정보 요청 및 recoil setting 컴포넌트
 * @param
 * @returns react components
 */
export function getDetailUserInfoR(loginInfo) {
    return __awaiter(this, void 0, void 0, function* () {
        let userInfo = {
            loginTime: "",
            userId: "",
            email: "",
            userName: "",
            phoneNumber: "",
            companyName: "",
            zoneName: "",
            department: "",
            role: "",
            language: "",
            classificationCode: -1,
            classification: "",
            //socialDtoOut: {},
            agreeMailReceipt: false,
            agreeTos: false,
            agreePersonalInfo: false,
            agreeData: false,
        };
        let data = null;
        data = yield HttpUtil.PromiseHttp({
            "httpMethod": "GET",
            "appPath": "/api/v2/user",
            appQuery: {},
            userToken: loginInfo.token,
        });
        if (data) {
            if (data.codeNum === CONST.API_200) {
                clog("IN USERUTIL : USERINFO : " + JSON.stringify(data.body));
                userInfo = {
                    loginTime: CUTIL.curTimeString(),
                    userId: data.body.userId,
                    email: data.body.email,
                    userName: data.body.userName,
                    phoneNumber: data.body.phoneNumber,
                    companyName: data.body.companyName,
                    zoneName: data.body.zoneName,
                    department: data.body.department,
                    role: data.body.role,
                    language: data.body.language,
                    classificationCode: data.body.classificationCode,
                    classification: data.body.classification,
                    //socialDtoOut: data.body.socialDtoOut,
                    agreeMailReceipt: data.body.agreeMailReceipt,
                    agreeTos: data.body.agreeTos,
                    agreePersonalInfo: data.body.agreePersonalInfo,
                    agreeData: data.body.agreeData,
                };
            }
        }
        return userInfo;
    });
}
