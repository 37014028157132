var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import * as CONST from "../../utils/Const";
import * as HttpUtil from "../../utils/api/HttpUtil";
import { userInfoLoginState, userInfoState } from "../../recoil/userState";
import { urlState } from "../../recoil/menuState";
/**
 * @brief My Account - 회원 탈퇴 컴포넌트
 * @param
 * @returns react components
 */
function UserWithdrawal(props) {
    //const [userInfo, setRecoilUserInfo] = useRecoilState(userInfoState);
    const userInfo = useRecoilValue(userInfoLoginState);
    const userId = props.userId;
    const resetRecoilUserInfo = useResetRecoilState(userInfoState);
    const setRecoilUrl = useSetRecoilState(urlState);
    const isOpen = props.userInfoPopup;
    //
    const navigate = useNavigate();
    //
    function onClickWithdrawal(e) {
        return __awaiter(this, void 0, void 0, function* () {
            let data = null;
            data = yield HttpUtil.PromiseHttp({
                httpMethod: "POST",
                appPath: `/api/v2/auth/logout?userId=${userInfo.userId}`,
                appQuery: {
                    userId: userInfo.userId,
                },
                userToken: userInfo.loginInfo.token,
            });
            if (data) {
                if (data.codeNum == 200) {
                    // Y/N 체크 필요?
                    sessionStorage.removeItem(CONST.STR_TOKEN);
                    sessionStorage.removeItem(CONST.STR_TOKEN_EXPIRETIME);
                    ////////////////////////
                    localStorage.removeItem(CONST.STR_KEEP_LOGIN_YN);
                    localStorage.removeItem(CONST.STR_USERID);
                    localStorage.removeItem(CONST.STR_USERROLE);
                    localStorage.removeItem(CONST.STR_REFRESHTOKEN);
                    localStorage.removeItem(CONST.STR_REFRESHTOKEN_EXPIRETIME);
                    //
                    resetRecoilUserInfo();
                }
                else {
                }
            }
            let data2 = null;
            data2 = yield HttpUtil.PromiseHttp({
                "httpMethod": "DELETE",
                "appPath": "/api/v2/user",
                appQuery: {
                    userId: userId,
                },
                userToken: userInfo.loginInfo.token,
            });
            if (data2) {
                if (data2.codeNum == 200) {
                    setRecoilUrl(CONST.URL_LOGIN);
                    navigate(CONST.URL_EHP);
                    alert("사용자 탈퇴가 완료되었습니다.");
                }
                else {
                }
            }
        });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-withdrawal", className: "popup-layer js-layer layer-out hidden popup-basic popup-withdrawal" },
            React.createElement("div", { className: "page-detail" },
                React.createElement("div", { className: "popup__head" },
                    React.createElement("h1", null, "\uC0AC\uC6A9\uC790 \uD0C8\uD1F4"),
                    React.createElement("button", { className: "btn btn-close js-close" },
                        React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
                React.createElement("div", { className: "popup__body" },
                    React.createElement("p", null,
                        "\uD68C\uC6D0 \uD0C8\uD1F4\uB97C \uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?",
                        React.createElement("br", null),
                        "\uAC00\uC785 \uC2DC \uB4F1\uB85D\uB41C \uBAA8\uB4E0 \uC815\uBCF4\uAC00 \uC0AD\uC81C\uB429\uB2C8\uB2E4.")),
                React.createElement("div", { className: "popup__footer" },
                    React.createElement("button", { type: "button", className: "bg-gray btn btn-close js-close" },
                        React.createElement("span", null, "\uCDE8\uC18C")),
                    React.createElement("button", { type: "button", className: " js-open", "data-pop": "pop-withdrawalcomplete", onClick: (e) => onClickWithdrawal(e) },
                        React.createElement("span", null, "\uD655\uC778")))))));
}
export default UserWithdrawal;
