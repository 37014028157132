/**
 * @url /serviceinspection
 * @CONST CONST.URL_SERVICEINSPECTION
 * @menu 서비스 요청
 * @mapping (구) 점검 출동 요청 -> LS ELECTRIC 온라인 서비스 신청 링크로 교체 되어 사용 x
 */
import React, { useState, useEffect } from "react";
// recoil
import { useRecoilState } from "recoil";
import { urlState, menuInfoState, menuListState } from '../../recoil/menuState';
//css
import "/static/css/swiper.css";
//utils
import clog from "../../utils/logUtils";
import * as CONST from "../../utils/Const";
import * as CUTIL from "../../utils/commUtils";
//component
import Header from "../header/Header";
//import ManagementMain from "../management/ManagementMain";
import EhpImageCrop from "../common/imagecrop/EhpImageCrop";
//import EhpPostCode from "../common/postcode/EhpPostCode";
import ServiceRequestMain from "../serviceRequest/ServiceReqeustMain";
import ServiceRequestSubMain from "../serviceRequest/ServiceReqeustSubMain";
import EhpDtlPostCode from "../common/postcode/EhpDtlPostCode";
//import EhpChatbot from "../common/link/EhpChatbot";
function ServiceRequestLayout(props) {
    //recoil
    const [urlInfo, setRecoilUrlInfo] = useRecoilState(urlState);
    const [menuInfo, setRecoilMenuInfo] = useRecoilState(menuInfoState);
    const [menuRecoilList, setRecoilMenuList] = useRecoilState(menuListState);
    //props
    const tabId = props.hasOwnProperty("tabId") ? props.tabId : 0;
    const tabType = props.hasOwnProperty("tabType") ? props.tabType : "main"; //main, subMain
    //tab init
    clog("IN LAYOUT : SERVICE REQ : INIT : " + urlInfo);
    const [tabMenuList, setTabMenuList] = useState(null);
    const [curTabMenu, setCurTabMenu] = useState(null);
    // 특정 화면에서 url로 menu 정보 추출하기 위함
    useEffect(() => {
        let tmenuList = menuRecoilList;
        if (tmenuList.length <= 0) {
            //tmenuList = JSON.parse(sessionStorage.getItem(CONST.STR_EHP_MENULIST)); 
        }
        //clog("IN LAYOUT : SREQUEST : URLINFO : " + urlInfo);
        let curMenu = null;
        let parentUrl = urlInfo;
        if (tabType === "subMain")
            parentUrl = CONST.URL_SERVICEWORKORDER;
        tmenuList.filter((smenu) => smenu.url === parentUrl).map((smenu, idx) => {
            if (idx === 0)
                curMenu = smenu;
        });
        //clog("IN LAYOUT : SREQUEST : CUR MENU : " + JSON.stringify(curMenu));
        const tempTab = [];
        if (tabType === "subMain") {
            tmenuList.filter((smenu) => (curMenu) && (smenu.parentCode === curMenu.menuCode)).map((smenu, idx) => {
                tempTab.push({ "tabId": idx, "tabName": smenu.menuName, "tabUrl": smenu.url });
            });
        }
        else { // tabType === main
            tmenuList.filter((smenu) => (curMenu) && (smenu.parentCode === curMenu.parentCode)).map((smenu, idx) => {
                tempTab.push({ "tabId": idx, "tabName": smenu.menuName, "tabUrl": smenu.url });
            });
        }
        setTabMenuList(tempTab);
        setCurTabMenu(tempTab[tabId]);
        //clog("IN LAYOUT : SREQUEST : TMENU LIST : " + JSON.stringify(tempTab));
    }, [urlInfo, tabId, menuRecoilList]);
    //let menuItem = menuRecoilList.filter((menu) => ((menu.parentCode == "MENU7")))
    //
    const [popUserApprrval, setPopUserApprrval] = useState(React.createElement(UserApprrvalPop, { popBody: null }));
    const [popSiteCeatePop, setPopSiteCeatePop] = useState(React.createElement(SiteCeatePop, { popBody: null }));
    const [popSmall, setPopSmall] = useState(React.createElement(SmallPop, { popBody: null }));
    const [popUserRole, setPopUserRole] = useState(React.createElement(UserRolePop, { popBody: null }));
    const [popSearch, setPopSearch] = useState(React.createElement(SearchPopup, { popBody: null }));
    const [popListView, setPopListView] = useState(React.createElement(ListViewPopup, { popBody: null }));
    //ehc-workorder
    const [popEhcDetailView, setPopEhcDetailView] = useState(React.createElement(EhcDetailViewPopup, { popBody: null }));
    // 공통 - crop
    const [popImgCrop, setPopImgCrop] = useState(React.createElement(EhpImageCrop, { popBody: null }));
    // 공통 - postcode
    const [popPostCode, setPopPostCode] = useState(React.createElement(EhpDtlPostCode, { popBody: null }));
    useEffect(() => {
        //setCurTabMenu(tabMenuList[tabId]);
    }, [tabId]);
    useEffect(() => {
        setPopUserApprrval(popUserApprrval);
        setPopSiteCeatePop(popSiteCeatePop);
        setPopSmall(popSmall);
        setPopUserRole(popUserRole);
        setPopSearch(popSearch);
        setPopListView(popListView);
        //ehc-workorder
        setPopEhcDetailView(popEhcDetailView);
        // 공통
        setPopImgCrop(popImgCrop);
        setPopPostCode(popPostCode);
    }, [popUserApprrval, popSiteCeatePop,
        popSmall, popUserRole, popSearch, popListView,
        popEhcDetailView,
        popImgCrop, popPostCode, // 공통
    ]);
    function handlePopWin(popType, popObj) {
        if (popType === "pop-userjoin-ok") {
            setPopUserApprrval(React.createElement(UserApprrvalPop, { popBody: popObj }));
        }
        else if (popType === "pop-userjoin-info") {
            setPopSiteCeatePop(React.createElement(SiteCeatePop, { popBody: popObj }));
        }
        else if (popType === "pop-small") {
            setPopSmall(React.createElement(SmallPop, { popBody: popObj }));
        }
        else if (popType === "pop-role") {
            setPopUserRole(React.createElement(UserRolePop, { popBody: popObj }));
        }
        else if (popType === "pop-search-small") {
            setPopSearch(React.createElement(SearchPopup, { popBody: popObj }));
        }
        else if (popType === "pop-list-view") {
            setPopListView(React.createElement(ListViewPopup, { popBody: popObj }));
        }
        else if (popType === "pop-list-ehcdetailview") { // ehc-workorder
            setPopEhcDetailView(React.createElement(EhcDetailViewPopup, { popBody: popObj }));
        }
        else if (popType === "pop-imgcrop") { //////////////////////////////////////////////////
            setPopImgCrop(React.createElement(EhpImageCrop, { popBody: popObj }));
        }
        else if (popType === "pop-dtlpostcode") {
            setPopPostCode(React.createElement(EhpDtlPostCode, { popBody: popObj }));
        }
        //
    }
    function handleCurTabMenu(tab) {
        // 승인 관리
        setPopUserApprrval(React.createElement(UserApprrvalPop, { popBody: null }));
        setPopSiteCeatePop(React.createElement(SiteCeatePop, { popBody: null }));
        setPopSiteCeatePop(React.createElement(SiteCeatePop, { popBody: null }));
        setPopUserRole(React.createElement(UserRolePop, { popBody: null }));
        setPopSearch(React.createElement(SearchPopup, { popBody: null }));
        setPopListView(React.createElement(ListViewPopup, { popBody: null }));
        //ehc-workorder
        setPopEhcDetailView(React.createElement(EhcDetailViewPopup, { popBody: null }));
        // 공통
        setPopImgCrop(React.createElement(EhpImageCrop, { popBody: null }));
        setPopPostCode(React.createElement(EhpDtlPostCode, { popBody: null }));
        //
        setCurTabMenu(tab);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "wrap" },
            React.createElement(Header, null),
            (tabType === "main") && React.createElement(ServiceRequestMain, { setPopWin: handlePopWin, tabMenuList: tabMenuList, curTabMenu: curTabMenu, tabType: tabType, setCurTabMenu: handleCurTabMenu }),
            (tabType === "subMain") && (tabMenuList) && (curTabMenu) && React.createElement(ServiceRequestSubMain, { setPopWin: handlePopWin, tabMenuList: tabMenuList, curTabMenu: curTabMenu, tabType: tabType, setCurTabMenu: handleCurTabMenu })),
        popUserApprrval,
        popSiteCeatePop,
        popSmall,
        popUserRole,
        popSearch,
        popListView,
        popEhcDetailView,
        popImgCrop,
        popPostCode));
}
export default ServiceRequestLayout;
// 닫기버튼 추가 팝업
function UserApprrvalPop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-userjoin-ok", className: "popup-layer js-layer layer-out hidden page-detail userjoin-ok" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                (htmlHeaderBtn) && htmlHeaderBtn),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// 기본 팝업
function SiteCeatePop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-userjoin-info", className: "popup-layer js-layer hidden page-detail userjoin-ok" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// 작은 팝업
function SmallPop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-small", className: "popup-layer js-layer hidden layer-out popup-basic" },
            React.createElement("div", { className: "page-detail" },
                React.createElement("div", { className: "popup__head" },
                    (htmlHeader) && htmlHeader,
                    (htmlHeaderBtn) && htmlHeaderBtn),
                (CUTIL.isnull(popBody))
                    ? React.createElement(React.Fragment, null)
                    : popBody))));
}
// 아주 작은 팝업
function UserRolePop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-role", className: "popup-layer popup-basic hidden page-detail w400" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                (htmlHeaderBtn) && htmlHeaderBtn),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// 검색 팝업
function SearchPopup(props) {
    const popBody = props.popBody;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-search-small", className: "popup-layer layer-out js-layer hidden page-detail page-report page-list-in" },
            React.createElement("div", { className: "popup__head" },
                React.createElement("h1", { className: "icon-search" }, "\uAC80\uC0C9"),
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (popBody === null)
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// 모바일 상세 팝업
function ListViewPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-list-view", className: "popup-layer js-layer layer-out hidden page-report page-detail page-workplace page-info page-list-view" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
/////////////////////////////////////////////
// ehc-workorder popup
// check message 조회 팝업
function EhcDetailViewPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-list-ehcdetailview", className: "popup-layer js-layer layer-out hidden page-report page-detail page-workplace page-info page-list-view" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
function handelClose(e) {
    window.location.reload();
}
