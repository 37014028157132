import { atom } from "recoil";
// 햄버거 메뉴 클릭 여부 관리 Recoil
export const menuState = atom({
    key: 'menuState',
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const headerSettingState = atom({
    key: 'headerSettingState',
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const headerAlarmState = atom({
    key: 'headerAlarmState',
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const headerUserInfoState = atom({
    key: 'headerUserInfoState',
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const treeMenuState = atom({
    key: 'treeMenuState',
    // key의 값은 항상 고유값이어야 합니다.
    default: true,
});
// 이동할 URL 정보 관리 Recoil
export const urlState = atom({
    key: 'urlState',
    // key의 값은 항상 고유값이어야 합니다.
    default: "",
});
// 전체 Loading 여부 정보 관리 Recoil
export const loadingState = atom({
    key: 'loadingState',
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
// BOX Loading 여부 정보 관리 Recoil
export const loadingBoxState = atom({
    key: 'loadingBoxState',
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const menuInfoState = atom({
    key: 'menuInfoState',
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        menuCode: "",
        menuName: "",
        parentCode: "",
        description: "",
        isExternalUrl: false,
        url: "",
    }
});
export const menuListState = atom({
    key: 'menuListState',
    // key의 값은 항상 고유값이어야 합니다.
    default: [],
});
export const czoneInfoState = atom({
    key: 'czoneInfoState',
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        company: { companyId: "", companyName: "", },
        zone: { zoneId: "", zoneName: "", },
    }
});
