var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
// recoil
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { authState, userInfoState, } from './recoil/userState';
import { urlState, menuInfoState, } from './recoil/menuState';
import { headerSettingState, headerAlarmState, headerUserInfoState } from './recoil/menuState';
import { langState } from './recoil/langState';
//
//utils
import AutoLoginFn from "./components/AutoLoginFn";
import clog from "./utils/logUtils";
import * as CUTIL from "./utils/commUtils";
import * as CONST from "./utils/Const";
import * as USERUTILS from "./utils/user/userUtils";
import EhpPdfViewer from "./components/common/pdfviewer/EhpPdfViewer";
//ex-utils
import * as i18n from './utils/langs/i18nUtils';
//
import Logout from "./components/header/Logout";
import MyAccount from "./components/header/MyAccount";
//component
import EhpLogin from "./components/login/Login";
import MainLayout from "./components/layout/MainLayout";
import CheckHistoryLayout from "./components/layout/CheckHistoryLayout";
import CheckReportLayout from "./components/layout/CheckReportLayout";
import AdminLayout from "./components/layout/AdminLayout";
import ManagementLayout from "./components/layout/ManagementLayout";
import NotFoundLayout from "./components/layout/NotFoundLayout";
import SystemErrorLayout from "./components/layout/SystemErrorLayout";
import IntroLayout from "./components/layout/IntroLayout";
import EhpCertification from "./components/common/certification/EhpCertification";
import ServiceRequestLayout from "./components/layout/ServiceRequestLayout";
import IntroductionLayout from "./components/layout/IntroductionLayout";
import DeviceStatusLayout from "./components/layout/DeviceStatusLayout";
import DndDashboardLayout from "./components/layout/DndDashboardLayout";
import EquipmentLifeLayout from "./components/layout/EquipmentLifeLayout";
function Ehp() {
    //recoil
    const [userInfo, setRecoilUserInfo] = useRecoilState(userInfoState);
    const [langs, setRecoilLangs] = useRecoilState(langState); // recoil langState  
    const goUrl = useRecoilValue(urlState);
    const isAuth = useRecoilValue(authState);
    const [settingPopup, setRecoilSettingPopup] = useRecoilState(headerSettingState);
    const [alarmPopup, setRecoilAlarmPopup] = useRecoilState(headerAlarmState);
    const [userInfoPopup, setRecoilUserInfoPopup] = useRecoilState(headerUserInfoState);
    ////////////////////////////////////////////////////
    //const locationHook = useLocation();
    useEffect(() => {
        // Mobile 체크
        function handleOnClickGlobal(e) {
            var modalTag = document.querySelector(".dimm");
            var isModal = (!CUTIL.isnull(modalTag)) ? (modalTag.style.display === "block") ? true : false : false;
            if (isModal)
                return;
            var clickTag = e.target;
            //clog("ON CLICK GLOVAL : " + clickTag.tagName);
            var headerBtnArea = clickTag.closest(".header__etc");
            var headerUserTag = clickTag.closest(".header__userinfo");
            var headerAlarmTag = clickTag.closest(".header__alarm");
            var headerSettingTag = clickTag.closest(".header__setting");
            var isHeaderArea = false;
            if (!CUTIL.isnull(headerBtnArea))
                isHeaderArea = true;
            if (!CUTIL.isnull(headerUserTag))
                isHeaderArea = true;
            if (!CUTIL.isnull(headerAlarmTag))
                isHeaderArea = true;
            if (!CUTIL.isnull(headerSettingTag))
                isHeaderArea = true;
            //clog("ON CLICK GLOVAL : isHeaderArea : " + isHeaderArea);
            if (!isHeaderArea) {
                setRecoilSettingPopup(false);
                setRecoilUserInfoPopup(false);
                setRecoilAlarmPopup(false); // toggle
            }
        }
        window.addEventListener("click", handleOnClickGlobal);
        return () => {
            window.removeEventListener('click', handleOnClickGlobal);
        };
    }, []);
    //clog("IN EHP : " + userInfo.userId + " : IS AUTH : " + isAuth);
    clog("WINDOW SIZE : " + window.innerHeight + " X " + window.innerWidth);
    const keepLoginYn = localStorage.getItem(CONST.STR_KEEP_LOGIN_YN);
    useEffect(() => {
        if (!isAuth) {
            //clog("IN EHP : NOT AUTH : " + keepLoginYn);
        }
        if (isAuth && (!userInfo.hasOwnProperty(userInfo.userId) || (userInfo.userId.length <= 0))) {
            // 리프레쉬 등 
            const sesToken = sessionStorage.getItem(CONST.STR_TOKEN);
            const sesTokenExpireTime = sessionStorage.getItem(CONST.STR_TOKEN_EXPIRETIME);
            const userId = localStorage.getItem(CONST.STR_USERID);
            const userRole = localStorage.getItem(CONST.STR_USERROLE);
            const refreshToken = localStorage.getItem(CONST.STR_REFRESHTOKEN);
            const refreshTokenExpireTime = localStorage.getItem(CONST.STR_REFRESHTOKEN_EXPIRETIME);
            const loginInfo = {
                "role": userRole,
                "token": sesToken,
                "tokenExpireTime": sesTokenExpireTime,
                "refreshToken": refreshToken,
                "refreshTokenExpireTime": refreshTokenExpireTime
            };
            (() => __awaiter(this, void 0, void 0, function* () {
                const detailUserInfo = yield USERUTILS.getDetailUserInfoR(loginInfo);
                setRecoilUserInfo({
                    loginTime: detailUserInfo.loginTime,
                    userId: detailUserInfo.userId,
                    email: detailUserInfo.email,
                    userName: detailUserInfo.userName,
                    phoneNumber: detailUserInfo.phoneNumber,
                    companyName: detailUserInfo.companyName,
                    zoneName: detailUserInfo.zoneName,
                    department: detailUserInfo.department,
                    role: detailUserInfo.role,
                    language: detailUserInfo.language,
                    classificationCode: detailUserInfo.classificationCode,
                    classification: detailUserInfo.classification,
                    //socialDtoOut: detailUserInfo.socialDtoOut,
                    agreeMailReceipt: detailUserInfo.agreeMailReceipt,
                    agreeTos: detailUserInfo.agreeTos,
                    agreePersonalInfo: detailUserInfo.agreePersonalInfo,
                    agreeData: detailUserInfo.agreeData,
                    loginInfo: loginInfo,
                });
                const frontLang = CUTIL.frontLangSet(detailUserInfo.language);
                yield i18n.changeLang(frontLang);
                setRecoilLangs(frontLang);
            }))();
        } // isAuth 체크
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(BrowserRouter, null,
            React.createElement(Routes, null,
                (isAuth)
                    ? (userInfo.userId.length > 0)
                        ? React.createElement(Route, { path: "/", element: React.createElement(GoPage, { isAuth: isAuth, userInfo: userInfo, goPage: goUrl }) })
                        : React.createElement(Route, { path: "/", element: React.createElement(EhpLogin, null) })
                    : React.createElement(Route, { path: "/", element: React.createElement(EhpLogin, null) }),
                React.createElement(Route, { path: CONST.URL_LOGIN, element: React.createElement(EhpLogin, null) }),
                React.createElement(Route, { path: CONST.URL_DASHBOARD, element: React.createElement(WrapLayout, { runComponent: React.createElement(DndDashboardLayout, null), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_MAIN_DASHBOARD, element: React.createElement(WrapLayout, { runComponent: React.createElement(MainLayout, null), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_CHECKHISTORY, element: React.createElement(WrapLayout, { runComponent: React.createElement(CheckHistoryLayout, null), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_REPORTVIEW, element: React.createElement(WrapLayout, { runComponent: React.createElement(CheckReportLayout, null), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_DEVICESTATUS, element: React.createElement(WrapLayout, { runComponent: React.createElement(DeviceStatusLayout, null), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_ADMIN, element: React.createElement(WrapLayout, { runComponent: React.createElement(AdminLayout, null), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_ADMIN_ITEM, element: React.createElement(WrapLayout, { runComponent: React.createElement(AdminLayout, { tabId: 1 }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_APPROVALMANAGEMENT, element: React.createElement(WrapLayout, { runComponent: React.createElement(ManagementLayout, null), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_USERMANAGEMENT, element: React.createElement(WrapLayout, { runComponent: React.createElement(ManagementLayout, { tabId: 1 }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_SITEMANAGEMENT, element: React.createElement(WrapLayout, { runComponent: React.createElement(ManagementLayout, { tabId: 2 }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_MESSAGEMANAGEMENT, element: React.createElement(WrapLayout, { runComponent: React.createElement(ManagementLayout, { tabId: 3 }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_MENUMANAGEMENT, element: React.createElement(WrapLayout, { runComponent: React.createElement(ManagementLayout, { tabId: 4 }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_CHECKSHEETMANAGEMENT, element: React.createElement(WrapLayout, { runComponent: React.createElement(ManagementLayout, { tabId: 5 }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_ECONSULTMANAGEMENT, element: React.createElement(WrapLayout, { runComponent: React.createElement(ManagementLayout, { tabId: 6 }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_SERVICEINSPECTION, element: React.createElement(WrapLayout, { runComponent: React.createElement(ServiceRequestLayout, { tabId: 1 }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_SERVICEWORKORDER, element: React.createElement(WrapLayout, { runComponent: React.createElement(ServiceRequestLayout, { tabId: 0, tabType: "subMain" }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_REQUESTWORKORDER, element: React.createElement(WrapLayout, { runComponent: React.createElement(ServiceRequestLayout, { tabId: 1, tabType: "subMain" }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_HISTORYWORKORDER, element: React.createElement(WrapLayout, { runComponent: React.createElement(ServiceRequestLayout, { tabId: 2, tabType: "subMain" }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_DEVICECHANGEWORKORDER, element: React.createElement(WrapLayout, { runComponent: React.createElement(ServiceRequestLayout, { tabId: 3, tabType: "subMain" }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_DEVICESLIFE, element: React.createElement(WrapLayout, { runComponent: React.createElement(EquipmentLifeLayout, { tabId: 0 }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_DEVICESLIFEREPORT, element: React.createElement(WrapLayout, { runComponent: React.createElement(EquipmentLifeLayout, { tabId: 1 }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_INTRODUCTIONINQUIRT, element: React.createElement(WrapLayout, { runComponent: React.createElement(IntroductionLayout, { tabId: 2 }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_LANDING, element: React.createElement(WrapLayout, { runComponent: React.createElement(IntroLayout, { tabId: 0 }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_EHC_INTRO, element: React.createElement(WrapLayout, { runComponent: React.createElement(IntroLayout, { tabId: 1 }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_HELP, element: React.createElement(WrapLayout, { runComponent: React.createElement(IntroLayout, { tabId: 2 }), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_COMM_PDFVIEWER, element: React.createElement(EhpPdfViewer, null) }),
                React.createElement(Route, { path: CONST.URL_VERIFYEMAIL, element: React.createElement(EhpCertification, null) }),
                React.createElement(Route, { path: CONST.URL_SYSTEM_ERROR, element: React.createElement(WrapLayout, { runComponent: React.createElement(SystemErrorLayout, null), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: CONST.URL_NOT_FOUND, element: React.createElement(WrapLayout, { runComponent: React.createElement(NotFoundLayout, null), userInfo: userInfo, isAuth: isAuth }) }),
                React.createElement(Route, { path: "*", element: React.createElement(WrapLayout, { runComponent: React.createElement(NotFoundLayout, null), userInfo: userInfo, isAuth: isAuth }) })),
            AutoLoginFn())));
}
export default Ehp;
function WrapLayout(props) {
    const isAuth = props.isAuth;
    const userInfo = props.userInfo;
    const runComponent = props.runComponent;
    const [goUrl, setRecoilUrl] = useRecoilState(urlState);
    const setRecoilMenuInfo = useSetRecoilState(menuInfoState);
    //const [userInfo, setRecoilUserInfo] = useRecoilState(userInfoState);
    const navigate = useNavigate();
    //clog("IN EHP : TOKEN : WrapLayout : " + isAuth + " : " + userInfo.loginInfo.token);
    useEffect(() => {
        if (!isAuth) {
            //setRecoilUrl(window.location.pathname);
            navigate(CONST.URL_EHP);
        }
    }, []);
    useEffect(() => {
        // non-user로 로그인 시  강제 landing page로 이동 hook
        if (userInfo.loginInfo.role == CONST.USERROLE_NONE) {
            setRecoilUrl(CONST.URL_LANDING);
            navigate(CONST.URL_EHP);
        }
    }, [userInfo.loginInfo.role]);
    useEffect(() => {
        if (CONST.URL_EHP !== window.location.pathname) {
            clog("IN EHP : LOCATION : SET RECOIL URL : " + window.location.pathname);
            /*
            const savedMenuList = JSON.parse(sessionStorage.getItem(CONST.STR_EHP_MENULIST)); // 특정 화면에서 url로 menu 정보 추출하기 위함
            savedMenuList.filter((smenu)=>smenu.url===window.location.pathname).map((smenu, idx)=>{
              if (idx===0) setRecoilMenuInfo(smenu);
            });
            */
            setRecoilUrl(window.location.pathname);
        }
    }, []);
    if (goUrl.length <= 0) {
        //return (<>URL을 알수 없습니다.</>);
    }
    return (React.createElement(React.Fragment, null,
        runComponent,
        React.createElement(Logout, null),
        React.createElement(MyAccount, { userInfo: userInfo }),
        React.createElement(Loading, { isAuth: isAuth }),
        React.createElement(Dimm, null)));
}
function GoPage(props) {
    const url = props.goPage;
    const userInfo = props.userInfo;
    const isAuth = props.isAuth;
    if (url.length <= 0) {
        //return (<>URL을 알수 없습니다.</>);
    }
    if (!isAuth)
        return React.createElement(EhpLogin, null);
    //clog("IN EHP : GO PAGE : " + url + " : " + JSON.stringify(userInfo));
    switch (url) {
        case CONST.URL_VERIFYEMAIL: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(EhpCertification, null), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_DASHBOARD: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(DndDashboardLayout, null), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_MAIN_DASHBOARD: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(MainLayout, null), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_CHECKHISTORY: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(CheckHistoryLayout, null), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_REPORTVIEW: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(CheckReportLayout, null), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_DEVICESTATUS: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(DeviceStatusLayout, null), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_LANDING: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(IntroLayout, { tabId: 0 }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_EHC_INTRO: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(IntroLayout, { tabId: 1 }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_HELP: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(IntroLayout, { tabId: 2 }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_ADMIN: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(AdminLayout, null), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_ADMIN_ITEM: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(AdminLayout, { tabId: 1 }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_APPROVALMANAGEMENT: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(ManagementLayout, { tabId: 0 }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_USERMANAGEMENT: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(ManagementLayout, { tabId: 1 }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_SITEMANAGEMENT: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(ManagementLayout, { tabId: 2 }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_MESSAGEMANAGEMENT: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(ManagementLayout, { tabId: 3 }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_MENUMANAGEMENT: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(ManagementLayout, { tabId: 4 }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_CHECKSHEETMANAGEMENT: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(ManagementLayout, { tabId: 5 }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_ECONSULTMANAGEMENT: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(ManagementLayout, { tabId: 6 }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_SERVICEINSPECTION: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(ServiceRequestLayout, { tabId: 1 }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_SERVICEWORKORDER: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(ServiceRequestLayout, { tabId: 0, tabType: "subMain" }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_REQUESTWORKORDER: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(ServiceRequestLayout, { tabId: 1, tabType: "subMain" }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_HISTORYWORKORDER: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(ServiceRequestLayout, { tabId: 2, tabType: "subMain" }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_DEVICECHANGEWORKORDER: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(ServiceRequestLayout, { tabId: 3, tabType: "subMain" }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_INTRODUCTIONINQUIRT: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(IntroductionLayout, null), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_DEVICESLIFE: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(EquipmentLifeLayout, { tabId: 0 }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_DEVICESLIFEREPORT: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(EquipmentLifeLayout, { tabId: 1 }), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_NOT_FOUND: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(NotFoundLayout, null), userInfo: userInfo, isAuth: isAuth }));
        }
        case CONST.URL_SYSTEM_ERROR: {
            return (React.createElement(WrapLayout, { runComponent: React.createElement(SystemErrorLayout, null), userInfo: userInfo, isAuth: isAuth }));
        }
        default: {
            //return (<NotFound/>)
            return (React.createElement(EhpLogin, null));
        }
    }
}
function Dimm(props) {
    return (React.createElement("div", { className: "dimm" }));
}
function Loading(props) {
    const isAuth = props.isAuth;
    return (React.createElement("div", { className: (isAuth) ? "" : "loading" },
        React.createElement("span", { className: "hide" }, "\uB85C\uB529\uC911")));
}
