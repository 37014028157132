/**
 * @url /reportview
 * @CONST CONST.URL_REPORTVIEW
 * @menu Report
 * @mapping Test Report 화면
 */
import React, { useState } from "react";
// css
import "/static/css/style.css";
import "/static/css/content.css";
import "/static/css/swiper.css";
// component
import Header from "../header/Header";
import CheckReportMain from "../checkReport/CheckReportMain";
function CheckReportLayout() {
    //
    const [itemDetail, setItemDetail] = useState({
        "ehcType": "BASIC",
        "ehcPos": "",
        "id": -1,
        "itemName": "",
        "serialNo": "",
        "itemStatus": "",
        "itemStep": null,
        "responsible": null,
        "assessment": {
            "assessmentId": null,
            "totalComment": null,
            "reportId": null,
            "updatedTime": null,
            "isTempSave": null
        },
        "checkStep": { "checkStepId": null, "name": null }
    });
    function handleItemDetail(val) {
        setItemDetail(val);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "wrap" },
            React.createElement(Header, null),
            React.createElement(CheckReportMain, { setItemDetail: handleItemDetail }))));
}
export default CheckReportLayout;
