/**
 * @url /checkhistory
 * @CONST CONST.URL_CHECKHISTORY
 * @menu Report
 * @mapping 진단점검 Report 화면
 */
import React, { useEffect, useState } from "react";
// css
import "/static/css/style.css";
import "/static/css/content.css";
import "/static/css/swiper.css";
// component
import Header from "../header/Header";
import HistoryMain from "../checkhistory/HistoryMain";
import ListDetail from "../main/itemlist/ListDetail";
import * as CUTIL from "../../utils/commUtils";
function CheckHistoryLayout() {
    const [popListView, setPopListView] = useState(React.createElement(ListViewPopup, { popBody: null }));
    const [popFilterView, setPopFilterView] = useState(React.createElement(FilterViewPopup, { popBody: null }));
    const [popSearch, setPopSearch] = useState(React.createElement(SearchPopup, { popBody: null }));
    useEffect(() => {
        setPopListView(popListView);
        setPopFilterView(popFilterView);
        setPopSearch(popSearch);
        //
    }, [popListView, popFilterView, popSearch
    ]);
    function handlePopWin(popType, popObj) {
        if (popType === "pop-list-view") {
            setPopListView(React.createElement(ListViewPopup, { popBody: popObj }));
        }
        else if (popType === "pop-filter") {
            setPopFilterView(React.createElement(FilterViewPopup, { popBody: popObj }));
        }
        else if (popType === "pop-search-small") {
            setPopSearch(React.createElement(SearchPopup, { popBody: popObj }));
        }
        function handleCurTabMenu(tab) {
            setPopListView(React.createElement(ListViewPopup, { popBody: null }));
            setPopFilterView(React.createElement(FilterViewPopup, { popBody: null }));
            setPopSearch(React.createElement(SearchPopup, { popBody: null }));
            //
        }
        //
    }
    //
    const [itemDetail, setItemDetail] = useState({
        "ehcType": "BASIC",
        "ehcPos": "",
        "id": -1,
        "itemName": "",
        "serialNo": "",
        "itemStatus": "",
        "itemStep": null,
        "responsible": null,
        "assessment": {
            "assessmentId": null,
            "totalComment": null,
            "reportId": null,
            "updatedTime": null,
            "isTempSave": null
        },
        "checkStep": { "checkStepId": null, "name": null }
    });
    function handleItemDetail(val) {
        setItemDetail(val);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "wrap" },
            React.createElement(Header, null),
            React.createElement(HistoryMain, { setItemDetail: handleItemDetail, setPopWin: handlePopWin })),
        popListView,
        popFilterView,
        popSearch,
        React.createElement(ListDetail, { itemDetail: itemDetail })));
}
export default CheckHistoryLayout;
// 모바일 상세 팝업
function ListViewPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-list-view", className: "popup-layer js-layer layer-out hidden page-report page-detail page-workplace page-info page-list-view" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
function FilterViewPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-filter", className: "popup-layer js-layer layer-out hidden page-detail page-report" },
            React.createElement("div", { className: "popup__head" },
                React.createElement("h1", { className: "icon-filter" }, "Filter"),
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// 검색 팝업
function SearchPopup(props) {
    const popBody = props.popBody;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-search-small", className: "popup-layer layer-out js-layer hidden page-detail page-report " },
            React.createElement("div", { className: "popup__head" },
                React.createElement("h1", { className: "icon-search" }, "\uAC80\uC0C9"),
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (popBody === null)
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
