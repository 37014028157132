/**
 * @url /notfound
 * @CONST CONST.URL_NOT_FOUND
 * @menu 없음
 * @mapping 404 오류 화면
 */
import React from "react";
// css
import "/static/css/style.css";
import "/static/css/content.css";
// component
import Header from "../header/Header";
import NotFound from "../common/notfound/NotFound";
function NotFoundLayout() {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "wrap" },
            React.createElement(Header, null),
            React.createElement(NotFound, null))));
}
export default NotFoundLayout;
