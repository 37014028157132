/**
 * @url /approvalmanagement, /usermanagement, /sitemanagement, /messagemanagement, /menumanagement, /checksheetmanagement, /econsultmanagement
 * @CONST CONST.URL_APPROVALMANAGEMENT, CONST.URL_USERMANAGEMENT, CONST.URL_SITEMANAGEMENT, CONST.URL_MESSAGEMANAGEMENT, CONST.URL_MENUMANAGEMENT,CONST.URL_CHECKSHEETMANAGEMENT, CONST.URL_ECONSULTMANAGEMENT
 * @menu Managemnet
 * @mapping 승인 관리 화면, 사용자 관리 화면, 사업장 관리 화면, 메시지 관리 화면, 메뉴 관리 화면, Check Sheet 관리 화면, 이메일 상담 관리 화면
 */
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
// recoil
import { useRecoilState } from "recoil";
import { urlState, menuInfoState, menuListState } from '../../recoil/menuState';
//css
import "/static/css/swiper.css";
import * as CUTIL from "../../utils/commUtils";
//component
import Header from "../header/Header";
import ManagementMain from "../management/ManagementMain";
import EhpImageCrop from "../common/imagecrop/EhpImageCrop";
import EhpPostCode from "../common/postcode/EhpPostCode";
function ManagementLayout(props) {
    //trans, navigate, ref 
    const navigate = useNavigate();
    //recoil
    const [urlInfo, setRecoilUrlInfo] = useRecoilState(urlState);
    const [menuInfo, setRecoilMenuInfo] = useRecoilState(menuInfoState);
    const [menuRecoilList, setRecoilMenuList] = useRecoilState(menuListState);
    //props
    const tabId = props.hasOwnProperty("tabId") ? props.tabId : 0;
    //tab init
    //clog("IN LAYOUT : MANAGEMENT : INIT : " + urlInfo);
    const tabMenuList1 = [ /*
      { tabId: 0, tabName: "승인 관리", tabUrl:CONST.URL_APPROVALMANAGEMENT, tabType: "TAB_APPROVAL" },
      { tabId: 1, tabName: "사용자 관리", tabUrl:CONST.URL_USERMANAGEMENT, tabType: "TAB_USER" },
      { tabId: 2, tabName: "사업장 관리", tabUrl:CONST.URL_SITEMANAGEMENT, tabType: "TAB_SITE" },
      { tabId: 3, tabName: "메세지 관리", tabUrl:CONST.URL_MESSAGEMANAGEMENT, tabType: "TAB_MESSAGE" },
      { tabId: 4, tabName: "메뉴 관리", tabUrl:CONST.URL_MENUMANAGEMENT, tabType: "TAB_MENU" },
      { tabId: 5, tabName: "Check Sheet 관리", tabUrl:CONST.URL_CHECKSHEETMANAGEMENT, tabType: "TAB_CHECK" },
    */];
    const [tabMenuList, setTabMenuList] = useState(null);
    const [curTabMenu, setCurTabMenu] = useState(null);
    // 특정 화면에서 url로 menu 정보 추출하기 위함
    useEffect(() => {
        let tmenuList = menuRecoilList;
        if (tmenuList.length <= 0) {
            //tmenuList = JSON.parse(sessionStorage.getItem(CONST.STR_EHP_MENULIST)); 
        }
        //clog("IN LAYOUT : MANAGEMENT : TMENU LIST : " + JSON.stringify(tmenuList));
        let curMenu = null;
        tmenuList.filter((smenu) => smenu.url === urlInfo).map((smenu, idx) => {
            if (idx === 0)
                curMenu = smenu;
        });
        //clog("IN LAYOUT : MANAGEMENT : CUR MENU : " + JSON.stringify(curMenu));
        const tempTab = [];
        tmenuList.filter((smenu) => (curMenu) && (smenu.parentCode === curMenu.parentCode)).map((smenu, idx) => {
            //if (idx===tabId) setCurTabMenu({"tabId":idx, "tabName":smenu.menuName, "tabUrl":smenu.url});
            tempTab.push({ "tabId": idx, "tabName": smenu.menuName, "tabUrl": smenu.url });
        });
        setTabMenuList(tempTab);
        //clog("IN LAYOUT : MANAGEMENT : TMENU LIST : " + JSON.stringify(tempTab));
        setCurTabMenu(tempTab[tabId]);
    }, [urlInfo, tabId, menuRecoilList]);
    //승인관리 - 신규가입자 팝업
    const [popUserApprrval, setPopUserApprrval] = useState(React.createElement(UserApprrvalPop, { popBody: null }));
    const [popSiteCeatePop, setPopSiteCeatePop] = useState(React.createElement(SiteCeatePop, { popBody: null }));
    // 사업장 승인 요청
    const [popSiteApprovalPop, setPopSiteApprovalPop] = useState(React.createElement(SiteApprovalPop, { popBody: null }));
    // 진단점검 승인 요청
    const [popCheckApprovalPop, setPopCheckApprovalPop] = useState(React.createElement(CheckApprovalPop, { popBody: null }));
    // 사업장 관리 ZoneView Popup 조회
    const [popZoneView, setPopZoneView] = useState(React.createElement(ZoneViewPopup, { popBody: null }));
    const [popZoneUpdate, setPopZoneUpdate] = useState(React.createElement(ZoneUpdatePopup, { popBody: null }));
    // 메세지 관리
    const [popMessageInsert, setPopMessageInsert] = useState(React.createElement(MessageInsertPopup, { popBody: null }));
    const [popMessageView, setPopMessageView] = useState(React.createElement(MessageViewPopup, { popBody: null }));
    const [popMessageUpdate, setPopMessageUpdate] = useState(React.createElement(MessageUpdatePopup, { popBody: null }));
    const [popMessageDelete, setPopMessageDelete] = useState(React.createElement(MessageDeletePopup, { popBody: null }));
    // SpgCheckSheet 버전 관리
    const [popSpgVersionChange, setPopSpgVersionChange] = useState(React.createElement(SpgVersionChangePopup, { popBody: null }));
    const [popSpgCheckView, setPopSpgCheckView] = useState(React.createElement(SpgCheckViewPopup, { popBody: null }));
    const [popSpgCheckUpdate, setPopSpgCheckUpdate] = useState(React.createElement(SpgCheckUpdatePopup, { popBody: null }));
    const [popSpgCheckInsert, setPopSpgCheckInsert] = useState(React.createElement(SpgCheckInsertPopup, { popBody: null }));
    // Email Consult 답변 관리
    const [popEmailConsultAnswerOk, setPopEmailConsultAnswerOk] = useState(React.createElement(EamilConsultAnswerOkPopup, { popBody: null }));
    const [popEmailConsultAnswer, setPopEmailConsultAnswer] = useState(React.createElement(EamilConsultAnswerPopup, { popBody: null }));
    //사용자 관리
    const [popUserEnabled, setPopUserEnabled] = useState(React.createElement(UserEnabledPop, { popBody: null }));
    const [popUserPassword, setPopUserPassword] = useState(React.createElement(UserPasswordPop, { popBody: null }));
    const [popUserDelete, setPopUserDelete] = useState(React.createElement(UserDeletePop, { popBody: null }));
    const [popUserRole, setPopUserRole] = useState(React.createElement(UserRolePop, { popBody: null }));
    // 공통 - crop
    const [popImgCrop, setPopImgCrop] = useState(React.createElement(EhpImageCrop, { popBody: null }));
    // 공통 - postcode
    const [popPostCode, setPopPostCode] = useState(React.createElement(EhpPostCode, { popBody: null }));
    useEffect(() => {
        //setCurTabMenu(tabMenuList[tabId]);
    }, [tabId]);
    useEffect(() => {
        // 승인 관리
        setPopUserApprrval(popUserApprrval);
        setPopSiteCeatePop(popSiteCeatePop);
        setPopSiteApprovalPop(popSiteApprovalPop);
        setPopCheckApprovalPop(popCheckApprovalPop);
        // 사업장 관리
        setPopZoneView(popZoneView);
        setPopZoneUpdate(popZoneUpdate);
        // 메시지 관리
        setPopMessageInsert(popMessageInsert);
        setPopMessageView(popMessageView);
        setPopMessageUpdate(popMessageUpdate);
        setPopMessageDelete(popMessageDelete);
        // SpgCheckSheet 버전 관리
        setPopSpgVersionChange(popSpgVersionChange);
        setPopSpgCheckView(popSpgCheckView);
        setPopSpgCheckUpdate(popSpgCheckUpdate);
        setPopSpgCheckInsert(popSpgCheckInsert);
        // Email Consult 답변 관리
        setPopEmailConsultAnswerOk(popEmailConsultAnswerOk);
        setPopEmailConsultAnswer(popEmailConsultAnswer);
        // 사용자 관리
        setPopUserEnabled(popUserEnabled);
        setPopUserPassword(popUserPassword);
        setPopUserDelete(popUserDelete);
        setPopUserRole(popUserRole);
        // 공통
        setPopImgCrop(popImgCrop);
        setPopPostCode(popPostCode);
    }, [popUserApprrval, popSiteCeatePop,
        popSiteApprovalPop, popSiteApprovalPop, popCheckApprovalPop,
        popZoneView, popZoneUpdate,
        popMessageInsert, popMessageView, popMessageUpdate, popMessageDelete,
        popSpgVersionChange, popSpgCheckView, popSpgCheckUpdate, popSpgCheckInsert,
        popEmailConsultAnswerOk, popEmailConsultAnswer,
        popUserEnabled, popUserPassword, popUserDelete, popUserRole,
        popImgCrop, popPostCode, // 공통
    ]);
    function handlePopWin(popType, popObj) {
        if (popType === "pop-userjoin-ok") {
            setPopUserApprrval(React.createElement(UserApprrvalPop, { popBody: popObj }));
        }
        else if (popType === "pop-userjoin-info") {
            setPopSiteCeatePop(React.createElement(SiteCeatePop, { popBody: popObj }));
        }
        else if (popType === "pop-workplace-ok") {
            setPopSiteApprovalPop(React.createElement(SiteApprovalPop, { popBody: popObj }));
        }
        else if (popType === "pop-casecheck-ok") {
            setPopCheckApprovalPop(React.createElement(CheckApprovalPop, { popBody: popObj }));
        }
        else if (popType === "pop-listbox-detail") {
            setPopZoneView(React.createElement(ZoneViewPopup, { popBody: popObj }));
        }
        else if (popType === "pop-listbox-detail-edit") {
            setPopZoneUpdate(React.createElement(ZoneUpdatePopup, { popBody: popObj }));
        }
        else if (popType === "pop-message-add") {
            setPopMessageInsert(React.createElement(MessageInsertPopup, { popBody: popObj }));
        }
        else if (popType === "pop-message") {
            setPopMessageView(React.createElement(MessageViewPopup, { popBody: popObj }));
        }
        else if (popType === "pop-message-edit") {
            setPopMessageUpdate(React.createElement(MessageUpdatePopup, { popBody: popObj }));
        }
        else if (popType === "pop-delete") {
            setPopMessageDelete(React.createElement(MessageDeletePopup, { popBody: popObj }));
        }
        else if (popType === "pop-version-ok") {
            setPopSpgVersionChange(React.createElement(SpgVersionChangePopup, { popBody: popObj }));
        }
        else if (popType === "pop-spgcheck-detail") {
            setPopSpgCheckView(React.createElement(SpgCheckViewPopup, { popBody: popObj }));
        }
        else if (popType === "pop-spgcheck-edit") {
            setPopSpgCheckUpdate(React.createElement(SpgCheckUpdatePopup, { popBody: popObj }));
        }
        else if (popType === "pop-spgcheck-add") {
            setPopSpgCheckInsert(React.createElement(SpgCheckInsertPopup, { popBody: popObj }));
        }
        else if (popType === "pop-answer-ok") {
            setPopEmailConsultAnswerOk(React.createElement(EamilConsultAnswerOkPopup, { popBody: popObj }));
        }
        else if (popType === "pop-answer") {
            setPopEmailConsultAnswer(React.createElement(EamilConsultAnswerPopup, { popBody: popObj }));
        }
        else if (popType === "pop-imgcrop") { //////////////////////////////////////////////////
            setPopImgCrop(React.createElement(EhpImageCrop, { popBody: popObj }));
        }
        else if (popType === "pop-postcode") {
            setPopPostCode(React.createElement(EhpPostCode, { popBody: popObj }));
        }
        else if (popType === "pop-enabled") {
            setPopUserEnabled(React.createElement(UserEnabledPop, { popBody: popObj }));
        }
        else if (popType === "pop-password") {
            setPopUserPassword(React.createElement(UserPasswordPop, { popBody: popObj }));
        }
        else if (popType === "pop-delete") {
            setPopUserDelete(React.createElement(UserDeletePop, { popBody: popObj }));
        }
        else if (popType === "pop-role") {
            setPopUserRole(React.createElement(UserRolePop, { popBody: popObj }));
        }
        //
    }
    function handleCurTabMenu(tab) {
        // 승인 관리
        setPopUserApprrval(React.createElement(UserApprrvalPop, { popBody: null }));
        setPopSiteCeatePop(React.createElement(SiteCeatePop, { popBody: null }));
        setPopSiteApprovalPop(React.createElement(SiteApprovalPop, { popBody: null }));
        setPopCheckApprovalPop(React.createElement(CheckApprovalPop, { popBody: null }));
        // 사업장 관리
        setPopZoneView(React.createElement(ZoneViewPopup, { popBody: null }));
        setPopZoneUpdate(React.createElement(ZoneUpdatePopup, { popBody: null }));
        // 메시지 관리
        setPopMessageInsert(React.createElement(MessageInsertPopup, { popBody: null }));
        setPopMessageView(React.createElement(MessageViewPopup, { popBody: null }));
        setPopMessageUpdate(React.createElement(MessageUpdatePopup, { popBody: null }));
        setPopMessageDelete(React.createElement(MessageDeletePopup, { popBody: null }));
        // SpgCheckSheet 버전 관리
        setPopSpgVersionChange(React.createElement(SpgVersionChangePopup, { popBody: null }));
        setPopSpgCheckView(React.createElement(SpgCheckViewPopup, { popBody: null }));
        setPopSpgCheckUpdate(React.createElement(SpgCheckUpdatePopup, { popBody: null }));
        setPopSpgCheckInsert(React.createElement(SpgCheckInsertPopup, { popBody: null }));
        // Email Consult 답변 관리
        setPopEmailConsultAnswerOk(React.createElement(EamilConsultAnswerOkPopup, { popBody: null }));
        setPopEmailConsultAnswer(React.createElement(EamilConsultAnswerPopup, { popBody: null }));
        // 사용자 관리
        setPopUserEnabled(React.createElement(UserEnabledPop, { popBody: null }));
        setPopUserPassword(React.createElement(UserPasswordPop, { popBody: null }));
        setPopUserDelete(React.createElement(UserDeletePop, { popBody: null }));
        setPopUserRole(React.createElement(UserRolePop, { popBody: null }));
        // 공통
        setPopImgCrop(React.createElement(EhpImageCrop, { popBody: null }));
        setPopPostCode(React.createElement(EhpPostCode, { popBody: null }));
        //
        setCurTabMenu(tab);
        //
        navigate(tab.tabUrl);
        setRecoilUrlInfo(tab.tabUrl);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "wrap" },
            React.createElement(Header, null),
            (tabMenuList) && (curTabMenu) && React.createElement(ManagementMain, { setPopWin: handlePopWin, tabMenuList: tabMenuList, curTabMenu: curTabMenu, setCurTabMenu: handleCurTabMenu })),
        popUserApprrval,
        popSiteCeatePop,
        popSiteApprovalPop,
        popCheckApprovalPop,
        popZoneView,
        popZoneUpdate,
        popMessageInsert,
        popMessageView,
        popMessageUpdate,
        popMessageDelete,
        popSpgVersionChange,
        popSpgCheckView,
        popSpgCheckUpdate,
        popSpgCheckInsert,
        popEmailConsultAnswerOk,
        popEmailConsultAnswer,
        popUserEnabled,
        popUserPassword,
        popUserDelete,
        popUserRole,
        popImgCrop,
        popPostCode));
}
export default ManagementLayout;
// 승인관리 신규기입자
function UserApprrvalPop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-userjoin-ok", className: "popup-layer js-layer layer-out hidden page-detail userjoin-ok" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                (htmlHeaderBtn) && htmlHeaderBtn),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// userApproval-site
function SiteCeatePop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-userjoin-info", className: "popup-layer js-layer hidden page-detail userjoin-ok" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// 사업장 승인 요청
function SiteApprovalPop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-workplace-ok", className: "popup-layer js-layer layer-out hidden page-detail workplace-ok" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// 진단점검요청 승인 
function CheckApprovalPop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-casecheck-ok", className: "popup-layer js-layer layer-out hidden page-detail userjoin-ok casecheck-ok" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// Zone 팝업 조회 화면 
function ZoneViewPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-listbox-detail", className: "popup-layer js-layer layer-out hidden page-detail workplace-ok" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// Zone 팝업 수정 화면 
function ZoneUpdatePopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-listbox-detail-edit", className: "popup-layer js-layer layer-out hidden page-detail workplace-ok" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// check message 등록 팝업
function MessageInsertPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const setListReload = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("setListReload")
                ? popBody.props.setListReload
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-message-add", className: "popup-layer js-layer layer-out hidden page-detail checker-message" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close", onClick: (e) => (setListReload) && setListReload(true) },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// check message 조회 팝업
function MessageViewPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const setListReload = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("setListReload")
                ? null //popBody.props.setListReload
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-message", className: "popup-layer js-layer layer-out hidden page-detail checker-message" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close", onClick: (e) => (setListReload) && setListReload(true) },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// check message 수정 팝업
function MessageUpdatePopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-message-edit", className: "popup-layer js-layer hidden page-detail checker-message" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// check message 삭제 팝업
function MessageDeletePopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-delete", className: "popup-layer js-layer layer-out hidden popup-basic" },
            React.createElement("div", { className: "page-detail" },
                React.createElement("div", { className: "popup__head" },
                    (htmlHeader) && htmlHeader,
                    React.createElement("button", { className: "btn btn-close js-close" },
                        React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
                (CUTIL.isnull(popBody))
                    ? React.createElement(React.Fragment, null)
                    : popBody))));
}
// spg version 변경 팝업
function SpgVersionChangePopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-version-ok", className: "popup-layer js-layer layer-out hidden popup-basic" },
            React.createElement("div", { className: "page-detail" },
                React.createElement("div", { className: "popup__head" },
                    (htmlHeader) && htmlHeader,
                    React.createElement("button", { className: "btn btn-close js-close" },
                        React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
                (CUTIL.isnull(popBody))
                    ? React.createElement(React.Fragment, null)
                    : popBody))));
}
// spg check 항목 조회 팝업
function SpgCheckViewPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-spgcheck-detail", className: "popup-layer js-layer layer-out hidden page-detail checker-sheet" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// spg check 항목 수정 팝업
function SpgCheckUpdatePopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-spgcheck-edit", className: "popup-layer js-layer layer-out hidden page-detail checker-sheet" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// spg check 항목 등록 팝업
function SpgCheckInsertPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-spgcheck-add", className: "popup-layer js-layer layer-out hidden page-detail checker-sheet" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// Email Consult Answer OK 조회 팝업
function EamilConsultAnswerOkPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-answer-ok", className: "popup-layer js-layer layer-out hidden page-detail checker-message" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// Email Consult Answer 등록 팝업
function EamilConsultAnswerPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-answer", className: "popup-layer js-layer layer-out hidden page-detail checker-message" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
//사용자 관리 - 활성화 팝업
function UserEnabledPop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-enabled", className: "popup-layer js-layer hidden layer-out popup-basic" },
            React.createElement("div", { className: "page-detail" },
                React.createElement("div", { className: "popup__head" },
                    (htmlHeader) && htmlHeader,
                    (htmlHeaderBtn) && htmlHeaderBtn),
                (CUTIL.isnull(popBody))
                    ? React.createElement(React.Fragment, null)
                    : popBody))));
}
// 사용자 관리  - password초기화 팝업
function UserPasswordPop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-password", className: "popup-layer js-layer hidden layer-out popup-basic" },
            React.createElement("div", { className: "page-detail" },
                React.createElement("div", { className: "popup__head" },
                    (htmlHeader) && htmlHeader,
                    (htmlHeaderBtn) && htmlHeaderBtn),
                (CUTIL.isnull(popBody))
                    ? React.createElement(React.Fragment, null)
                    : popBody))));
}
// 사용자 관리  - 리스트삭제 팝업
function UserDeletePop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-delete", className: "popup-layer js-layer hidden layer-out popup-basic" },
            React.createElement("div", { className: "page-detail" },
                React.createElement("div", { className: "popup__head" },
                    (htmlHeader) && htmlHeader,
                    (htmlHeaderBtn) && htmlHeaderBtn),
                (CUTIL.isnull(popBody))
                    ? React.createElement(React.Fragment, null)
                    : popBody))));
}
// 사용자 관리  - userType 팝업
function UserRolePop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-role", className: "popup-layer js-layer hidden layer-out popup-basic" },
            React.createElement("div", { className: "page-detail" },
                React.createElement("div", { className: "popup__head" },
                    (htmlHeader) && htmlHeader,
                    (htmlHeaderBtn) && htmlHeaderBtn),
                (CUTIL.isnull(popBody))
                    ? React.createElement(React.Fragment, null)
                    : popBody))));
}
function handelClose(e) {
    window.location.reload();
}
