/**
 * @url /dashboard
 * @CONST CONST.URL_DASHBOARD
 * @menu DashBoard
 * @mapping DashBoard 화면
 */
import React, { useState, useEffect } from "react";
// recoil
import { useRecoilState } from "recoil";
import { urlState, menuInfoState, menuListState } from '../../recoil/menuState';
// css
import "/static/css/style.css";
import "/static/css/content.css";
//utils
import clog from "../../utils/logUtils";
import * as CONST from "../../utils/Const";
import * as CUTIL from "../../utils/commUtils";
//component
import Header from "../header/Header";
import EhpImageCrop from "../common/imagecrop/EhpImageCrop";
import EhpDtlPostCode from "../common/postcode/EhpDtlPostCode";
import DndDashboardMain from "../dashboard/DndDashboardMain";
function DndDashboardLayout(props) {
    //recoil
    const [urlInfo, setRecoilUrlInfo] = useRecoilState(urlState);
    const [menuInfo, setRecoilMenuInfo] = useRecoilState(menuInfoState);
    const [menuRecoilList, setRecoilMenuList] = useRecoilState(menuListState);
    //props
    const tabId = props.hasOwnProperty("tabId") ? props.tabId : 0;
    const tabType = props.hasOwnProperty("tabType") ? props.tabType : "main"; //main, subMain
    //tab init
    clog("IN LAYOUT : SERVICE : INIT : " + urlInfo);
    const [tabMenuList, setTabMenuList] = useState(null);
    const [curTabMenu, setCurTabMenu] = useState(null);
    const [itemDetail, setItemDetail] = useState({
        "ehcType": "BASIC",
        "ehcPos": "",
        "itemId": "",
        "itemName": "",
        "serialNo": "",
        "itemStatus": "",
        "itemStep": null,
        "responsible": null,
        "assessment": {
            "assessmentId": null,
            "totalComment": null,
            "reportId": null,
            "updatedTime": null,
            "isTempSave": null
        },
        "checkStep": { "checkStepId": null, "name": null }
    });
    const [reportInfo, setReportInfo] = useState(null);
    // 특정 화면에서 url로 menu 정보 추출하기 위함
    useEffect(() => {
        let tmenuList = menuRecoilList;
        // if (tmenuList.length <= 0) {
        //tmenuList = JSON.parse(sessionStorage.getItem(CONST.STR_EHP_MENULIST)); 
        // }
        //clog("IN LAYOUT : MANAGEMENT : TMENU LIST : " + JSON.stringify(tmenuList));
        // let curMenu: any = null;
        // tmenuList.filter((smenu) => smenu.url === urlInfo).map((smenu, idx) => {
        // tmenuList.map((smenu, idx) => {
        // curMenu = smenu;
        // });
        //clog("IN LAYOUT : MANAGEMENT : CUR MENU : " + JSON.stringify(curMenu));
        const tempTab = [];
        // tmenuList.filter((smenu) => (curMenu) && (smenu.parentCode === curMenu.parentCode)).map((smenu, idx) => {
        tmenuList.map((smenu, idx) => {
            //if (idx===tabId) setCurTabMenu({"tabId":idx, "tabName":smenu.menuName, "tabUrl":smenu.url});
            tempTab.push({
                "tabId": idx,
                "tabName": smenu.menuName,
                "tabUrl": smenu.url,
                "tabType": (smenu.url === CONST.URL_ADMIN) ? "TAB_ZONE" : (smenu.url === CONST.URL_ADMIN_ITEM) ? "TAB_DEVICE" : ""
            });
        });
        setTabMenuList(tempTab);
        //clog("IN LAYOUT : MANAGEMENT : TMENU LIST : " + JSON.stringify(tempTab));
        setCurTabMenu(tempTab[tabId]);
    }, [urlInfo, tabId, menuRecoilList]);
    //let menuItem = menuRecoilList.filter((menu) => ((menu.parentCode == "MENU7")))
    //
    const [popUserApprrval, setPopUserApprrval] = useState(React.createElement(UserApprrvalPop, { popBody: null }));
    const [popSiteCeatePop, setPopSiteCeatePop] = useState(React.createElement(SiteCeatePop, { popBody: null }));
    const [popSmall, setPopSmall] = useState(React.createElement(SmallPop, { popBody: null }));
    const [popUserRole, setPopUserRole] = useState(React.createElement(UserRolePop, { popBody: null }));
    const [popSearch, setPopSearch] = useState(React.createElement(SearchPopup, { popBody: null }));
    const [popFilter, setPopFilter] = useState(React.createElement(FilterPopup, { popBody: null }));
    const [popChange, setPopChange] = useState(React.createElement(ChangePopup, { popBody: null }));
    const [popListView, setPopListView] = useState(React.createElement(ListViewPopup, { popBody: null }));
    // 성적서 pop
    const [popTestView, setPopTestView] = useState(React.createElement(PopupReportView, { popBody: null }));
    //ehc-workorder
    const [popEhcDetailView, setPopEhcDetailView] = useState(React.createElement(EhcDetailViewPopup, { popBody: null }));
    // 공통 - crop
    const [popImgCrop, setPopImgCrop] = useState(React.createElement(EhpImageCrop, { popBody: null }));
    // 공통 - postcode
    const [popPostCode, setPopPostCode] = useState(React.createElement(EhpDtlPostCode, { popBody: null }));
    useEffect(() => {
        //setCurTabMenu(tabMenuList[tabId]);
    }, [tabId]);
    useEffect(() => {
        setPopUserApprrval(popUserApprrval);
        setPopSiteCeatePop(popSiteCeatePop);
        setPopSmall(popSmall);
        setPopUserRole(popUserRole);
        setPopSearch(popSearch);
        setPopFilter(popFilter);
        setPopChange(popChange);
        setPopListView(popListView);
        //
        setPopTestView(popTestView);
        //ehc-workorder
        setPopEhcDetailView(popEhcDetailView);
        // 공통
        setPopImgCrop(popImgCrop);
        setPopPostCode(popPostCode);
    }, [popUserApprrval, popSiteCeatePop,
        popSmall, popUserRole, popSearch, popListView,
        popEhcDetailView, popFilter, popTestView,
        popChange,
        popImgCrop, popPostCode, // 공통
    ]);
    function handlePopWin(popType, popObj) {
        if (popType === "pop-userjoin-ok") {
            setPopUserApprrval(React.createElement(UserApprrvalPop, { popBody: popObj }));
        }
        else if (popType === "pop-userjoin-info") {
            setPopSiteCeatePop(React.createElement(SiteCeatePop, { popBody: popObj }));
        }
        else if (popType === "pop-small") {
            setPopSmall(React.createElement(SmallPop, { popBody: popObj }));
        }
        else if (popType === "pop-role") {
            setPopUserRole(React.createElement(UserRolePop, { popBody: popObj }));
        }
        else if (popType === "pop-search-small") {
            setPopSearch(React.createElement(SearchPopup, { popBody: popObj }));
        }
        else if (popType === "pop-filter") {
            setPopFilter(React.createElement(FilterPopup, { popBody: popObj }));
        }
        else if (popType === "pop-change") {
            setPopChange(React.createElement(ChangePopup, { popBody: popObj }));
        }
        else if (popType === "pop-list-view") {
            setPopListView(React.createElement(ListViewPopup, { popBody: popObj }));
        }
        else if (popType === "pop-downtest") {
            setPopTestView(React.createElement(PopupReportView, { popBody: popObj }));
        }
        else if (popType === "pop-list-ehcdetailview") { // ehc-workorder
            setPopEhcDetailView(React.createElement(EhcDetailViewPopup, { popBody: popObj }));
        }
        else if (popType === "pop-imgcrop") { //////////////////////////////////////////////////
            setPopImgCrop(React.createElement(EhpImageCrop, { popBody: popObj }));
        }
        else if (popType === "pop-dtlpostcode") {
            setPopPostCode(React.createElement(EhpDtlPostCode, { popBody: popObj }));
        }
        //
    }
    function handleCurTabMenu(tab) {
        // 승인 관리
        setPopUserApprrval(React.createElement(UserApprrvalPop, { popBody: null }));
        setPopSiteCeatePop(React.createElement(SiteCeatePop, { popBody: null }));
        setPopSiteCeatePop(React.createElement(SiteCeatePop, { popBody: null }));
        setPopUserRole(React.createElement(UserRolePop, { popBody: null }));
        setPopSearch(React.createElement(SearchPopup, { popBody: null }));
        setPopFilter(React.createElement(FilterPopup, { popBody: null }));
        setPopChange(React.createElement(ChangePopup, { popBody: null }));
        setPopListView(React.createElement(ListViewPopup, { popBody: null }));
        //
        setPopTestView(React.createElement(PopupReportView, { popBody: null }));
        //ehc-workorder
        setPopEhcDetailView(React.createElement(EhcDetailViewPopup, { popBody: null }));
        // 공통
        setPopImgCrop(React.createElement(EhpImageCrop, { popBody: null }));
        setPopPostCode(React.createElement(EhpDtlPostCode, { popBody: null }));
        //
        setCurTabMenu(tab);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "wrap", className: "main" },
            React.createElement(Header, null),
            (tabMenuList) && (curTabMenu) && React.createElement(DndDashboardMain, { setPopWin: handlePopWin, tabMenuList: tabMenuList, curTabMenu: curTabMenu, tabType: tabType, setCurTabMenu: handleCurTabMenu })),
        popUserApprrval,
        popSiteCeatePop,
        popSmall,
        popUserRole,
        popSearch,
        popFilter,
        popChange,
        popListView,
        popTestView,
        popEhcDetailView,
        popImgCrop,
        popPostCode));
}
export default DndDashboardLayout;
// 닫기버튼 추가 팝업
function UserApprrvalPop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-userjoin-ok", className: "popup-layer js-layer layer-out hidden page-detail userjoin-ok" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                (htmlHeaderBtn) && htmlHeaderBtn),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// 기본 팝업
function SiteCeatePop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-userjoin-info", className: "popup-layer js-layer hidden page-detail userjoin-ok" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// 작은 팝업
function SmallPop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-small", className: "popup-layer js-layer hidden layer-out popup-basic" },
            React.createElement("div", { className: "page-detail" },
                React.createElement("div", { className: "popup__head" },
                    (htmlHeader) && htmlHeader,
                    (htmlHeaderBtn) && htmlHeaderBtn),
                (CUTIL.isnull(popBody))
                    ? React.createElement(React.Fragment, null)
                    : popBody))));
}
// 아주 작은 팝업
function UserRolePop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-role", className: "popup-layer popup-basic hidden page-detail w400" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                (htmlHeaderBtn) && htmlHeaderBtn),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// 검색 팝업
function SearchPopup(props) {
    const popBody = props.popBody;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-search-small", className: "popup-layer layer-out js-layer hidden page-detail page-report page-list-in" },
            React.createElement("div", { className: "popup__head" },
                React.createElement("h1", { className: "icon-search" }, "\uAC80\uC0C9"),
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (popBody === null)
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
// 모바일 상세 팝업
function ListViewPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-list-view", className: "popup-layer js-layer layer-out hidden page-report page-detail page-workplace page-info page-list-view" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
/////////////////////////////////////////////
// ehc-workorder popup
// check message 조회 팝업
function EhcDetailViewPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-list-ehcdetailview", className: "popup-layer js-layer layer-out hidden page-report page-detail page-workplace page-info page-list-view" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (CUTIL.isnull(popBody))
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
function FilterPopup(props) {
    const popBody = props.popBody;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-filter", className: "popup-layer js-layer layer-out hidden page-detail page-workplace page-report" },
            React.createElement("div", { className: "popup__head" },
                React.createElement("h1", { className: "icon-filter" }, "Filter"),
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (popBody === null)
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
function ChangePopup(props) {
    const popBody = props.popBody;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-change", className: "popup-layer js-layer hidden layer-out popup-basic" },
            React.createElement("div", { className: "page-detail" },
                React.createElement("div", { className: "popup__head" },
                    React.createElement("h1", null, "\uB178\uD6C4\uAD50\uCCB4 \uC694\uCCAD"),
                    (htmlHeaderBtn) && htmlHeaderBtn),
                (popBody === null)
                    ? React.createElement(React.Fragment, null)
                    : popBody))));
}
function PopupReportView(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "pop-downtest", className: "popup-layer js-layer layer-out hidden w720" },
            React.createElement("div", { className: "popup__head" },
                (htmlHeader) && htmlHeader,
                React.createElement("button", { className: "btn btn-close js-close" },
                    React.createElement("span", { className: "hide" }, "\uB2EB\uAE30"))),
            (popBody === null)
                ? React.createElement(React.Fragment, null)
                : popBody)));
}
function handelClose(e) {
    window.location.reload();
}
